import { CLIENT } from './config'
import { API_URL } from '../config'
import { LOGIN_URL, LOGOUT_URL, ME_URL, REQUEST_PASSWORD_RESET } from './endpoints'

export const loginUser = () => {
  return window.location.assign(
    `${API_URL}${LOGIN_URL}?return_to=${encodeURIComponent(window.location.origin)}`
  )
}

export const logout = () => {
  return window.location.assign(
    `${API_URL}${LOGOUT_URL}?return_to=${encodeURIComponent(window.location.origin)}`
  )
}

export const fetchUser = async (): Promise<IUser> => {
  const user = (await CLIENT.get<IUser>(ME_URL)).data
  return user
}

export const requestPasswordReset = async (): Promise<void> => {
  return await CLIENT.post(REQUEST_PASSWORD_RESET)
}
