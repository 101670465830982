import { CLIENT } from './config'
import { camelize } from '../utils/camelize'
import { SMap } from 'global'

export async function fetchAll<T>(
  url: string,
  params: SMap<string | number> = {}
): Promise<T[]> {
  let data = (await CLIENT.get(url, { params })).data
  let result = data.data
  while (data.next) {
    data = (await CLIENT.get(data.next, { params })).data
    result = result.concat(data.data)
  }
  return camelize(result)
}
