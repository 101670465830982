import { fetchAll } from 'api/common'
import Axios from 'axios'
import * as _ from 'lodash'

import { DUMMY_LABEL } from '../constants'
import { SIDEBAR } from '../messages'
import { buildTitle, getArray } from '../utils'
import { CLIENT } from './config'
import { DATABASES_URL, FAVORITES_URL } from './endpoints'
import { IData, IDirectoryEntry, IFootnote, SMap } from 'global'

export const getAllDatabases = async (): Promise<IData> => {
  return await getDatabases()
}

const getDatabases = async (): Promise<IData> => {
  const result = await fetchAll<any>(DATABASES_URL)
  const entries = result.map(toDatabase)
  return { entries }
}

let lastToken = Axios.CancelToken.source()
export const getData = async (db: string, page: string): Promise<IData> => {
  const data: any = await getPageData(db, page)

  const entries = getArray(data.data.concept).map(toEntry)
  const footnotes = getFootnotes(data.data.footnotetext)
  const parent = getParent(data.data)

  return { parent, entries, footnotes, isPreview: data.isPreview }
}

export const getPageData = async (db: string, page: string) => {
  const path = `${DATABASES_URL}/${db}/menus/${page}`
  lastToken.cancel()
  lastToken = Axios.CancelToken.source()

  const result = await CLIENT.get<any>(path, { cancelToken: lastToken.token })
  return result.data
}

export const toDirectoryEntry = (
  data: any,
  name: string,
  databaseName: string
): IDirectoryEntry => {
  return {
    ...getParent(data),
    id: data.parent ? name : databaseName,
    type: data.parent ? 'page' : 'db',
    link: name,
  }
}

const getParent = (page: any): IDirectoryEntry => {
  const title = buildTitle(page)
  const default_title = SIDEBAR.DEFAULT_MENU_DESCRIPTION
  const footnote = _.find(getArray(page.title2), obj => _.has(obj, 'footnote'))
  const options: string[] = getArray(page.formheader).map((o: any) => o['#text'])

  if (!title && !footnote && options.length === 0) {
    // This is likely not a valid menu page
    return null
  }

  return {
    type: 'db',
    id: '',
    options,
    // A page with no title is just a skeleton to present the options menu
    description: title || default_title,
    footnoteId: footnote ? getFootnoteLabel(footnote.footnote) : undefined,
  }
}

export const getFootnotes = (data: any): SMap<IFootnote> => {
  if (!data) {
    return {}
  }
  const footnotes = getArray(data).map(toFootnote)
  const dict: SMap<IFootnote> = {}
  footnotes.forEach(f => (dict[f.id] = f))
  return dict
}

const toFootnote = (data: any): IFootnote => ({
  id: getFootnoteLabel(data),
  description: data.fntext,
})

const toDatabase = (data: any): IDirectoryEntry => ({
  type: 'db',
  id: data.name,
  description: data.description,
  link: data.rootMenuPageName,
  isEnabled: data.isEnabled,
  isPreview: data.isPreview,
})

export const toEntry = (data: any): IDirectoryEntry => {
  if (isEmpty(data)) {
    return toLabel(data)
  }
  const linksData = data.links?.link
  if (linksData) {
    const links = getArray(linksData)
    const link = links[0].code
    return link.startsWith('@') ? toPage({ ...data, link }) : toSeries(data, links)
  }
  return toLabel(data)
}

const isEmpty = (data: any) => {
  const keys = Object.keys(data)
  return keys.length === 1 && keys[0] === 'value'
}

const toPage = (data: any): IDirectoryEntry => {
  return {
    id: data.value,
    type: 'page',
    link: data.link,
    description: getDescription(data.value),
    children: getArray(data.concept).map(toEntry),
    footnoteId: getFootnoteLabel(data.footnote),
  }
}

const createId = (value: string): string =>
  value && value !== '\u2022' ? value : DUMMY_LABEL + rand()

const toLabel = (data: any): IDirectoryEntry => {
  const { value } = data
  const val = typeof value === 'string' ? value : value['#text']
  const id = createId(val)
  const description = val || DUMMY_LABEL + rand()
  return {
    id,
    type: 'label',
    description,
    footnoteId: getFootnoteLabel(data.footnote),
    children: getArray(data.concept).map(toEntry),
  }
}

const toSeries = (data: any, links: any[]): IDirectoryEntry => {
  const linksDict: SMap<string> = {}
  links.forEach(l => (linksDict[Number(l['@form']) - 1] = l.code))
  return {
    id: links[0].code,
    type: 'series',
    description: getDescription(data.value),
    children: getArray(data.concept).map(toEntry),
    links: linksDict,
    footnoteId: getFootnoteLabel(data.footnote),
  }
}

const getDescription = (data: any) => (typeof data === 'string' ? data : data['#text'])

const getFootnoteLabel = (data: any): string => data?.['@label']?.trim().slice(1, -1)

const rand = (max = 100000) => Math.floor(Math.random() * Math.floor(max))

interface TraverseRouteResponse {
  database_name: string
  pages: {
    name: string
    data: any
  }[]
}

export const traverseRouteForSeries = (dbName: string, seriesName: string) =>
  CLIENT.get<TraverseRouteResponse>(
    `${DATABASES_URL}/${dbName}/menus/traverse_for_series/${seriesName}`
  )

export const fetchFavouriteDatabases = async (): Promise<string[]> => {
  const response = await CLIENT.get<{ data: string[] }>(FAVORITES_URL)
  return response.data.data
}

export const createFavouriteDatabase = (databaseName: string) =>
  CLIENT.post<null>(`${FAVORITES_URL}/favorite/${databaseName}`, {
    databaseName,
  })

export const removeFavouriteDatabase = (databaseName: string) =>
  CLIENT.delete(`${FAVORITES_URL}/favorite/${databaseName}`)
