import * as React from 'react'

import { ADMIN_URL, API_KEY_LANDING_URL } from '../config'
import { SETTINGS_DROPDOWN } from '../messages'
import { bindBem } from '../bem'

import { isAdmin } from '../services/user'
import { ResponsiveContext } from './Responsive'

import { ReactComponent as PasswordIcon } from 'static/password.svg'
import { ReactComponent as KeyIcon } from 'static/key.svg'
import { ReactComponent as AdminIcon } from 'static/admin.svg'
import { ReactComponent as LogoutIcon } from 'static/logout.svg'

import './SettingsDropdown.scss'

interface IProps {
  role: Role
  onLogout: () => void
  onChangePassword: () => void
  isFooter?: boolean
  openUserProfileModal: () => void
}

export const SettingsDropdown = (props: IProps) => {
  const { block, element } = bindBem('SettingsDropdown')
  const { role, onLogout, isFooter, onChangePassword } = props
  return (
    <div className={block({ isFooter })} data-cy="settings_dropdown">
      <ResponsiveContext.Consumer>
        {ctx => {
          if (ctx.isMobile) {
            return null
          }
          // FIXME
          if (isAdmin(role)) {
            return (
              <a
                className={element('Entry')}
                href={ADMIN_URL}
                target="_blank"
                rel="noreferrer"
              >
                <div className={element('Img')}>
                  <AdminIcon />
                </div>
                {SETTINGS_DROPDOWN.ADMIN_SETTINGS}
              </a>
            )
          }
        }}
      </ResponsiveContext.Consumer>

      <a
        className={element('Entry')}
        href={API_KEY_LANDING_URL}
        target="_blank"
        rel="noreferrer"
      >
        <div className={element('Img')}>
          <KeyIcon />
        </div>
        {SETTINGS_DROPDOWN.API_KEY}
      </a>
      <div className={element('Entry')} onClick={onChangePassword}>
        <div className={element('Img')}>
          <PasswordIcon />
        </div>
        {SETTINGS_DROPDOWN.CHANGE_PASSWORD}
      </div>

      <div className={element('Entry')} onClick={onLogout}>
        <div className={element('Img')}>
          <LogoutIcon />
        </div>
        {SETTINGS_DROPDOWN.LOGOUT}
      </div>
    </div>
  )
}
