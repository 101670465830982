import React from 'react'

import { bindBem } from '../../bem'
import { ADVANCED_FUNCTIONS, FUNCTIONS_DIALOG } from '../../messages'
import { Button } from '../Button'

import './AdvancedFunctionForms.scss'

interface IProps {
  title: string
  series: TransformationOrSeries
  onCancel: () => void
  onSave: (t: ITransformation) => void
  frequency: Frequency
  isNested?: boolean
}

export interface IParametrizedProps extends IProps {
  function: AdvancedFunction
  setFormValidation: (v: boolean) => void
}

export const Variants: {
  [key in string]?: keyof (typeof ADVANCED_FUNCTIONS.OPTIONS)[]
} = {
  diff: ['diff', 'difa', 'difv'],
  'diff%': ['diff%', 'difa%', 'difv%'],
  diffl: ['diffl', 'difal', 'difvl'],
  movv: ['movv', 'movt', 'mova'],
}

const FunctionValues = Object.keys(Variants).reduce(
  (acc, cur) => [...acc, ...Variants[cur]],
  []
)

export const getParentVariant = (key: string) => {
  if (FunctionValues.indexOf(key) === -1) {
    return key
  }
  return Object.keys(Variants)[Math.floor(FunctionValues.indexOf(key) / 3)]
}

export interface IState {
  baseFunc: functionName
  func: functionName
  args: TransformationArg[]
  centered?: boolean
}

export interface IFormProps extends IParametrizedProps {
  state: IState
  setForm: (state: IState) => void
}

export const InnerForm: React.FC<
  IProps & {
    className: string
    onSave: () => void
    isValid: boolean
  }
> = props => {
  const { block, element } = bindBem(props.className)
  return (
    <div className={block()}>
      {props.children}
      {props.isNested && (
        <div className="row row-xs end-xs">
          <Button
            onClick={props.onCancel}
            text={FUNCTIONS_DIALOG.CANCEL}
            style="light"
            size="small"
          />
          <Button
            onClick={props.onSave}
            text={FUNCTIONS_DIALOG.ADD}
            style="dark"
            size="small"
            disabled={!props.isValid}
          />
        </div>
      )}
    </div>
  )
}
