export function parseCssImport(input: string) {
  const output: { [key: string]: string } = {}

  const cleanedInput = input
    .trim()
    .replace(/^:export\s*{?/, '')
    .replace(/}\s*$/, '')

  const kvPairs = cleanedInput.split(';')

  for (const kv of kvPairs) {
    const match = kv.match(/^\s*([^:]+):\s*([^;]+)/)

    if (match && match.length === 3) {
      const key = match[1].trim()
      output[key] = match[2].trim()
    }
  }

  return output
}
