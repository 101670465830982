// eslint-disable max-lines
import * as Sentry from '@sentry/react'
import { push } from 'connected-react-router'
import { debounce, isUndefined } from 'lodash'
import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connect } from 'react-redux'

import { IDataSeries, IFoundSeries, ILink, ISearchResults, SMap } from 'global'

import { bindBem } from '../bem'
import Loader from '../containers/SeriesContainer/Loader'
import { SEARCH_PANEL } from '../messages'
import { IRootState } from '../store'
import { buildDataDirectory } from '../store/Database'
import { getSeriesBySearch, searchSeries } from '../store/Series/thunks'
import { getDbSeriesId } from '../utils'
import SeriesAutocomplete from './Autocomplete'
import { Button } from './Button'
import { withResponsive } from './Responsive'
import { ReactComponent as SearchIcon } from 'static/series-search.svg'
import { DataDirectoryEntry } from './Sidebar/DataDirectoryEntry'

import noResults from '../static/search-no-results.svg'

import './SeriesSearch.scss'

export interface IProps {
  replaceIndex?: number
  onOpenSeries?: () => void
  isMobile: boolean
  children?: React.ReactNode
}

export interface IStateProps {
  dbNames: SMap<string>
  link: ILink
  variablesCount: number
  loadingSeriesId?: string
  selectedSeries: string[]
  lastIndex: number
  variables: IDataSeries[]
  currentDatabase: string
  pending: boolean
}

export interface IActionProps {
  openSeries: (dbId: string, id: string, append: boolean, index?: number) => void
  pushSeriesUrl: (link: ILink) => void
  buildDataDirectory: (dbName: string, id: string) => void
  searchSeries: (
    query: string,
    currentDatabase: string,
    lastSeriesId?: string,
    lastScore?: number,
    lastDbName?: string
  ) => Promise<ISearchResults>
}

type Props = IProps & IStateProps & IActionProps

interface IState {
  meta: Omit<ISearchResults, 'data'> | null
  query: string
  lastQuery: string
  results: IFoundSeries[]
  suggestions: IFoundSeries[]
  resultsPaneOpen: boolean
  isEmpty: boolean
  lastCurrentDatabase: string
  pending: boolean
}

const EMPTY: [] = []
const INITIAL: IState = {
  meta: null,
  resultsPaneOpen: false,
  isEmpty: false,
  query: '',
  lastQuery: '',
  results: EMPTY,
  suggestions: EMPTY,
  lastCurrentDatabase: null,
  pending: false,
}
export class SeriesSearch extends React.Component<Props, IState> {
  state: IState = INITIAL
  content = React.createRef<HTMLDivElement>()

  private search = debounce(async (query: string) => {
    if (query.length < 2) {
      return
    }
    await this.searchSuggestions(query)
  }, 500)

  static getDerivedStateFromProps(nextProps: Props, prevState: IState): IState {
    if (prevState.lastCurrentDatabase !== nextProps.currentDatabase) {
      return { ...INITIAL, lastCurrentDatabase: nextProps.currentDatabase }
    }

    return null
  }

  render() {
    const { block, element } = bindBem('SeriesSearch')

    const { children } = this.props
    const { resultsPaneOpen, isEmpty } = this.state

    return (
      <div className={block()}>
        <div className={element('SearchBar')}>
          <this.Autocomplete />
        </div>

        {resultsPaneOpen && isEmpty && <this.NoResultsPlaceholder />}
        {resultsPaneOpen && !isEmpty && <this.ResultsPlaceholder />}

        {!resultsPaneOpen && <div className={element('Content')}>{children}</div>}
      </div>
    )
  }

  private onSecondaryClick = (
    e: React.MouseEvent,
    databaseId: string,
    seriesId: string
  ) => {
    e.stopPropagation()
    this.openSeries(databaseId, seriesId, false, true)
  }

  private onShowResults = () => {
    this.setState({ resultsPaneOpen: true })
  }

  private searchSuggestions = async (query: string) => {
    this.setState({ pending: true })

    const response = await this.props.searchSeries(query, this.props.currentDatabase)

    this.setState({ lastQuery: query })

    if (isUndefined(response)) {
      return
    }

    this.setState({
      meta: {
        next: response.next,
        last_score: response.last_score,
        last_series_id: response.last_series_id,
      },
      results: [...response.data],
      suggestions: [...response.data],
      isEmpty: response.data.length === 0,
      pending: false,
    })
  }

  private nextPage = async (lastSeriesId: string, lastScore: number) => {
    const response = await this.props.searchSeries(
      this.state.lastQuery,
      this.props.currentDatabase,
      lastSeriesId,
      lastScore
    )

    if (isUndefined(response)) {
      return
    }

    this.setState(prev => {
      return {
        meta: {
          next: response.next,
          last_score: response.last_score,
          last_series_id: response.last_series_id,
        },
        results: [...prev.results, ...response.data],
      }
    })
  }

  private openSeries = (
    db: string,
    series: string,
    clearQuery: boolean,
    append: boolean
  ) => {
    this.props.openSeries(
      db,
      series,
      append,
      this.props.replaceIndex ?? this.props.lastIndex
    )
    if (clearQuery) {
      this.setState(() => ({ query: '' }))
    }
    this.loadDataDirectory(db, series)
    if (this.props.onOpenSeries) {
      this.props.onOpenSeries()
    }
    this.props.pushSeriesUrl(this.props.link)
  }

  private loadDataDirectory = async (db: string, series: string) => {
    this.props.buildDataDirectory(db, series)
  }

  private setQuery = (query: string) => {
    this.setState({ query })
    this.search(query)
  }
  private clearResults = async () => {
    this.setState(INITIAL)
  }

  private ResultsPlaceholder = () => {
    const { element } = bindBem('SeriesSearch')
    const { variablesCount, loadingSeriesId, selectedSeries, isMobile } = this.props
    const { lastQuery, results: lastResults, meta, pending } = this.state

    return (
      <div className={element('ResultsWrapper')}>
        {meta ? (
          <>
            <Loader loading={pending} />
            <div className={element('SearchResults')} ref={this.content}>
              <div
                className={element('ResultsInfo')}
              >{`${SEARCH_PANEL.RESULTS} "${lastQuery}"`}</div>

              <div className={element('ResultsSub')}>
                <div className={element('ClearResults')} onClick={this.clearResults}>
                  {SEARCH_PANEL.BACK}
                </div>
              </div>
              <InfiniteScroll
                getScrollParent={() => this.content.current}
                useWindow={false}
                loadMore={() => this.nextPage(meta.last_series_id, meta.last_score)}
                hasMore={meta && !pending && !!meta.next}
                pageStart={1}
                threshold={100}
              >
                {lastResults.map((series, i) => (
                  <DataDirectoryEntry
                    prefix
                    key={`${series.databaseId}-${series.id}-${i}`}
                    entry={{
                      type: 'series',
                      id: series.id,
                      description: `${series.id}@${series.databaseId}: ${series.description}`,
                    }}
                    onClick={() =>
                      this.openSeries(series.databaseId, series.id, false, false)
                    }
                    onSecondaryClick={
                      isMobile
                        ? undefined
                        : e => this.onSecondaryClick(e, series.databaseId, series.id)
                    }
                    variablesCount={variablesCount}
                    isLoading={getDbSeriesId(series) === loadingSeriesId}
                    isSelected={selectedSeries.includes(getDbSeriesId(series))}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <Loader loading={true} />
        )}
      </div>
    )
  }

  private NoResultsPlaceholder = () => {
    const { element } = bindBem('SeriesSearch')
    const { lastQuery } = this.state
    return (
      <div className={element('SearchResults')}>
        <div
          className={element('ResultsInfo')}
        >{`${SEARCH_PANEL.NO_RESULTS_INFO} "${lastQuery}"`}</div>
        <div className={element('ResultsSub')}>
          <div>{SEARCH_PANEL.NO_RESULTS_SUBINFO}</div>
          <div className={element('ClearResults')} onClick={this.clearResults}>
            {SEARCH_PANEL.BACK}
          </div>
        </div>
        <div className={element('Placeholder')}>
          <img src={noResults} />
        </div>
      </div>
    )
  }

  private openSuggestion = async (db: string, id: string, append: boolean) => {
    this.openSeries(db, id, true, append)
    this.clearResults()
    if (this.props.onOpenSeries) {
      this.props.onOpenSeries()
    }
  }
  private clearSuggestions = () => this.setState({ suggestions: EMPTY })

  private Autocomplete = () => {
    const { query, suggestions: foundSeries, pending } = this.state
    const inputProps = {
      value: query,
      onInput: (e: React.FormEvent<HTMLInputElement>) =>
        this.setQuery(e.currentTarget.value),
      onInputClear: () => {
        this.setQuery('')
        this.clearSuggestions()
      },
    }

    return (
      <div className="SeriesSearch__SearchInputGroup">
        <div className="SearchIcon">
          <SearchIcon />
        </div>
        <SeriesAutocomplete
          pending={pending}
          inputProps={inputProps}
          items={foundSeries}
          onSuggestionsFetchCleared={this.clearSuggestions}
          onSuggestionsFetchRequested={() => this.search(query)}
          onSelect={this.openSuggestion}
          onSearch={this.onShowResults}
          variablesCount={this.props.variablesCount}
        />
        <Button
          disabled={!query}
          icon={<SearchIcon />}
          onClick={this.onShowResults}
          style="dark"
        />
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { link, variables, loadingSeriesId } = state.series
  const { dbNames, pending } = state.databases
  return {
    pending,
    dbNames,
    link,
    variablesCount: variables.length,
    loadingSeriesId,
    selectedSeries: variables.map(getDbSeriesId),
    lastIndex: variables.length > 0 ? variables.length - 1 : 0,
    variables,
    currentDatabase: state.databases.route[0]?.id,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  openSeries: (dbId: string, id: string, append = false, index?: number) =>
    dispatch(getSeriesBySearch(dbId, id, append, index)),
  pushSeriesUrl: (link: ILink) => dispatch(push(link ? `/series/${link.id}` : '/series')),
  buildDataDirectory: (dbName: string, id: string) =>
    dispatch(buildDataDirectory(dbName, id)),
  searchSeries: (
    query: string,
    currentDatabase: string,
    lastSeriesId?: string,
    lastScore?: number,
    lastDbName?: string
  ) =>
    dispatch(searchSeries(query, currentDatabase, lastSeriesId, lastScore, lastDbName)),
})

export default Sentry.withProfiler(
  connect(mapStateToProps, mapDispatchToProps)(withResponsive(SeriesSearch))
)
