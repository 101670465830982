// eslint-disable max-lines
import { TDispatch } from '.'
import {
  LOGIN_ERROR,
  ORG_INACTIVE,
  RESEND_PASSWORD_RESET_REQUEST_ERROR,
  RESEND_PASSWORD_RESET_REQUEST_SUCCESS,
  RESTRICTED_LOGIN_ERROR,
  USER_INACTIVE,
} from 'messages'
import { fetchMenu } from 'store/Menu'
import { loginUser, requestPasswordReset } from '../api/users'

import { fetchDatabases } from './Database'
import { CLIENT, RequestError } from './../api/config'
import { ActionType, createAction, getType } from 'typesafe-actions'

import * as ga from '../analytics'

import * as users from '../api/users'
import * as sentry from '../sentry'

import { ACTIONS as ROOT_ACTIONS } from './Root'
import { IUser } from 'global'

export const ACTIONS = {
  setUser: createAction('SET_USER', resolve => (user: IUser) => resolve(user)),
  openToast: createAction('OPEN_TOAST', resolve => (toast: IToast) => resolve(toast)),
  closeToast: createAction('CLOSE_TOAST'),
  failLogin: createAction('FAIL_LOGIN', resolve => (flag: boolean) => resolve(flag)),
  mfaRequired: createAction('MFA_REQUIRED', resolve => (flag: boolean) => resolve(flag)),
  loginPending: createAction(
    'PENDING_LOGIN',
    resolve => (flag: boolean) => resolve(flag)
  ),
  setCredentials: createAction(
    'SET_CREDENTIALS',
    resolve => (credentials: ILoginForm) => resolve(credentials)
  ),
  setMFAHash: createAction('SET_MFA_HASH', resolve => (hash: string) => resolve(hash)),
  setLoginError: createAction(
    'SET_LOGIN_ERROR',
    resolve => (message: string) => resolve(message)
  ),
}

export type State = {
  user: IUser
  isLoggedIn: boolean
  toast: IToast
  loginFailed: boolean
  mfaRequired: boolean
  loginPending: boolean
  credentials: ILoginForm
  mfaHash: string
  loginError: string
}

const INITIAL_STATE = (): State => ({
  user: null,
  isLoggedIn: false,
  toast: {
    isOpen: false,
    title: '',
    subtitle: '',
    type: 'info',
    position: 'bottom-left',
    autoClose: true,
  },
  loginFailed: null,
  mfaRequired: null,
  loginPending: true,
  credentials: { email: '', password: '' },
  mfaHash: localStorage.getItem('mfaHash'),
  loginError: null,
})

export function reducer(state = INITIAL_STATE(), action: UsersAction): State {
  switch (action.type) {
    case getType(ACTIONS.setUser):
      return {
        ...state,
        user: { ...action.payload },
        isLoggedIn: action.payload !== null,
      }
    case getType(ACTIONS.openToast):
      return { ...state, toast: action.payload }
    case getType(ACTIONS.closeToast):
      return { ...state, toast: { ...state.toast, isOpen: false } }
    case getType(ACTIONS.mfaRequired):
      return { ...state, mfaRequired: Boolean(action.payload) }
    case getType(ACTIONS.loginPending):
      return { ...state, loginPending: Boolean(action.payload) }
    case getType(ACTIONS.failLogin):
      return { ...state, loginFailed: Boolean(action.payload) }
    case getType(ACTIONS.setCredentials):
      return { ...state, credentials: action.payload }
    case getType(ACTIONS.setMFAHash):
      return { ...state, mfaHash: action.payload }
    case getType(ACTIONS.setLoginError):
      return { ...state, loginError: action.payload }
    default:
      return state
  }
}

export type UsersAction = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>

export function loginStart() {
  return async (dispatch: TDispatch) => {
    ga.track('auth', 'login')

    dispatch(ACTIONS.setLoginError(null))
    dispatch(ACTIONS.loginPending(true))

    loginUser()
  }
}

export function loadUser() {
  return async (dispatch: TDispatch) => {
    ga.track('auth', 'login')

    dispatch(ACTIONS.setLoginError(null))
    dispatch(ACTIONS.loginPending(true))

    try {
      dispatch(fetchMe())
    } catch (e) {
      dispatch(loginErrorHandler(e))
    }
  }
}

export const loginErrorHandler = (error: RequestError) => async (dispatch: TDispatch) => {
  dispatch(ACTIONS.loginPending(false))

  const status = error?.response?.status
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const type = error?.response?.data?.type

  ga.track('auth', 'loginError', { status, type })

  if (status === 422) {
    dispatch(ACTIONS.failLogin(true))

    if (type === 'UserInactiveError') {
      dispatch(ACTIONS.setLoginError(USER_INACTIVE))
    } else if (type === 'OrganizationInactiveError') {
      dispatch(ACTIONS.setLoginError(ORG_INACTIVE))
    } else if (type === 'WrongIPError') {
      dispatch(ACTIONS.setLoginError(RESTRICTED_LOGIN_ERROR))
    } else {
      dispatch(ACTIONS.setLoginError(LOGIN_ERROR))
    }
  } else if ([400, 401, 403].includes(status)) {
    dispatch(ACTIONS.setLoginError(LOGIN_ERROR))
    dispatch(ACTIONS.failLogin(true))
  } else {
    throw error
  }
}

export function logout() {
  return async () => {
    ga.track('auth', 'logout')
    ga.resetTrackingFields()
    sentry.setUserScope(null)
    users.logout()
  }
}

export function logoutWithToast(toast: Partial<IToast>, timeout = 3000) {
  return async (dispatch: TDispatch) => {
    dispatch(openToast(toast))
    setTimeout(() => dispatch(logout()), timeout)
  }
}

export function fetchMe() {
  return async (dispatch: TDispatch) => {
    let user: IUser = null

    dispatch(ACTIONS.loginPending(true))

    try {
      user = await users.fetchUser()
    } catch (err) {
      dispatch(ROOT_ACTIONS.resetApp({ keepMaintenance: true }))
    }

    if (user) {
      dispatch(setUser(user))
    }

    dispatch(ACTIONS.loginPending(false))
  }
}

export function requestPasswordResetWithToast() {
  return async (dispatch: TDispatch) => {
    try {
      await requestPasswordReset()
      dispatch(openToast({ title: RESEND_PASSWORD_RESET_REQUEST_SUCCESS }))
    } catch {
      dispatch(openToast({ title: RESEND_PASSWORD_RESET_REQUEST_ERROR }))
    }
  }
}

export const trackUserOrganization = async (user: IUser) => {
  ga.setTrackingFields(user.email, user.organization_name, user.organization_type)
}

let timeoutId: any

export function openToast(toast: Partial<IToast>) {
  return async (dispatch: TDispatch) => {
    const defaultToast: IToast = {
      isOpen: true,
      title: '',
      subtitle: '',
      type: 'info',
      position: 'bottom-left',
      autoClose: true,
    }
    clearTimeout(timeoutId)
    const fullToast = { ...defaultToast, ...toast, isOpen: true }
    dispatch(ACTIONS.openToast(fullToast))
    if (fullToast.autoClose) {
      timeoutId = setTimeout(() => dispatch(ACTIONS.closeToast()), 5000)
    }
  }
}

export const setUser = (user: IUser) => {
  return async (dispatch: TDispatch) => {
    sentry.setUserScope(user)

    dispatch(ACTIONS.setUser(user))

    window.zE?.(() =>
      window.zE('webWidget', 'identify', {
        name: user.username,
        email: user.email,
      })
    )

    dispatch(fetchDatabases(user))
    dispatch(fetchMenu())

    trackUserOrganization(user)
  }
}

export const setupInterceptor = () => {
  return async (dispatch: TDispatch) => {
    CLIENT.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status in [429, 401]) {
          dispatch(logout())
        }

        if (!error?.__CANCEL__) {
          dispatch(
            openToast({
              title: 'An error occurred',
              subtitle: 'Please try again later',
              type: 'error',
            })
          )
        }

        return Promise.reject(error)
      }
    )
  }
}
