// eslint-disable max-lines

import * as React from 'react'
import { Route, Switch, Redirect, RouteProps, Router } from 'react-router-dom'
import { Main } from './components/Dashboard'
import { Login } from './components/Login'
import { connect } from 'react-redux'
import { IRootState, history } from './store'
import {
  fetchMe,
  ACTIONS as UserActions,
  setupInterceptor as setupUserInterceptor,
} from './store/User'
import {
  healthCheck,
  setupInterceptor as setupMaintenanceInterceptor,
} from './store/Maintenance'
import LoginForm from './containers/LoginForm'
import { Toast } from './components/Toast'
import { withResponsive } from './components/Responsive'
import Loader from './containers/SeriesContainer/Loader'
import AppLoader from './components/AppLoader'
import Maintenance from 'containers/Maintenance'
import ZendeskButton from './components/ZendeskButton'

interface IProps {
  isMobile: boolean
}

interface IStateProps {
  user?: IUser
  isLoggedIn: boolean
  isLoading: boolean
  isMaintenanceMode: boolean
  toast: IToast
}

interface IActionProps {
  fetchMe: () => void
  healthCheck: () => void
  setupMaintenanceInterceptor: () => void
  setupUserInterceptor: () => void
  closeToast: () => void
}

const LoginRoute = (props: RouteProps) => (
  <Login>
    <Route {...props} />
  </Login>
)

export class Routing extends React.Component<IProps & IStateProps & IActionProps> {
  constructor(props: IProps & IStateProps & IActionProps) {
    super(props)

    this.handleDocumentLoad = this.handleDocumentLoad.bind(this)
  }

  handleDocumentLoad() {
    window.zE?.(() => window.zE('webWidget', 'hide'))
  }

  componentDidMount() {
    this.props.setupMaintenanceInterceptor()
    this.props.setupUserInterceptor()
    // TODO: Uncomment when endpoint will be available on new API
    // this.props.healthCheck()
    this.props.fetchMe()

    window.addEventListener('load', this.handleDocumentLoad)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleDocumentLoad)
  }

  render() {
    const { isLoggedIn, isLoading, toast, isMaintenanceMode } = this.props

    return (
      <Router history={history}>
        <Loader loading={isLoading} src={<AppLoader />}>
          <Switch>
            {isLoading && <Route path="/" render={() => <AppLoader />} />}
            {!isLoading && isLoggedIn && <Route path="/" render={() => <Main />} />}
            {!isLoading && !isLoggedIn && <this.LoginRouter />}
          </Switch>

          <Toast toast={toast} onClose={this.props.closeToast} />
          {isMaintenanceMode && <Maintenance />}
          <ZendeskButton />
        </Loader>
      </Router>
    )
  }

  private LoginRouter = () => {
    return (
      <Switch>
        <LoginRoute exact path="/login" component={LoginForm} />
        <Redirect to="/login" />
      </Switch>
    )
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user, isLoggedIn, toast, loginPending } = state.user
  const { maintenance } = state.maintenance

  return {
    user,
    isLoggedIn,
    isLoading: (isLoggedIn && !user) || loginPending,
    isMaintenanceMode: maintenance,
    toast,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  fetchMe: () => dispatch(fetchMe()),
  healthCheck: () => dispatch(healthCheck()),
  setupMaintenanceInterceptor: () => dispatch(setupMaintenanceInterceptor()),
  setupUserInterceptor: () => dispatch(setupUserInterceptor()),
  closeToast: () => dispatch(UserActions.closeToast()),
})

export default connect<IStateProps, IActionProps>(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(Routing))
