import * as Redux from 'redux'
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { getType } from 'typesafe-actions'
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk'
import { createBrowserHistory } from 'history'

import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler'
import { errorHandler } from './errorHandler'

import * as user from './User'
import * as databases from './Database'
import * as series from './Series'
import * as menu from './Menu'
import * as maintenance from './Maintenance'
import { ACTIONS, RootAction } from './Root'

export const history = createBrowserHistory()

export interface IRootState {
  router?: RouterState
  databases: databases.State
  user: user.State
  series: series.State
  menu: menu.State
  maintenance: maintenance.State
}

export type TDispatch = ThunkDispatch<IRootState, {}, Redux.Action>

const appReducer = Redux.combineReducers({
  user: user.reducer,
  databases: databases.reducer,
  series: series.reducer,
  menu: menu.reducer,
  maintenance: maintenance.reducer,
})

export const rootReducer = (state: IRootState, action: RootAction) => {
  if (action.type === getType(ACTIONS.resetApp)) {
    state = action.payload.keepMaintenance
      ? ({ maintenance: state.maintenance } as any)
      : undefined
  }
  return appReducer(state, action)
}

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({
  onError: errorHandler,
})

export function configureStore(): Redux.Store<IRootState> {
  const middlewares = Redux.applyMiddleware(
    errorHandlerMiddleware,
    thunkMiddleware,
    routerMiddleware(history)
  )
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: { options: true } })
    : null
  const comp = Redux.compose(
    middlewares,
    reduxDevTools ? reduxDevTools : (f: any) => f
  ) as any
  return Redux.createStore(connectRouter(history)(rootReducer), comp)
}
