import React from 'react'
import { connect } from 'react-redux'
import { FunctionsDialog } from '../components/Sidebar/FunctionsDialog'
import { IRootState } from '../store'
import { track } from 'analytics'
import { rewriteSeries, isNestedFunction } from '../store/Transformations'
import { updateSeriesOnly } from '../store/Series'
import { hasTrendLine, Freq, getMinFrequency } from '../services/series'

export interface IProps {
  applyTo: 'all' | number
  onClose: () => void
}

export interface IStateProps {
  transformation?: ITransformation
  variables: IDataSeries[]
  frequency: Frequency
  noGrowthRatesAllowed: boolean
}

export interface IActionProps {
  applyFunctionTo: (
    transformation: ITransformation,
    i: number,
    cancellable?: boolean
  ) => void
}

export const canApplySA = (variables: IDataSeries[], applyTo: 'all' | number) => {
  const allowedFrequencies = new Set([Freq.Monthly, Freq.Quarterly])
  const res = applyTo === 'all' ? variables : [variables[applyTo]]
  return res.every(v => allowedFrequencies.has(v.frequency))
}

export const canApplyFXConversion = (
  variables: IDataSeries[],
  applyTo: 'all' | number
) => {
  const allowedDataTypes = new Set(['US$', 'LocCur'])
  const res = applyTo === 'all' ? variables : [variables[applyTo]]
  return res.every(v => allowedDataTypes.has(v.dataType))
}

export class FunctionsContainer extends React.Component<
  IProps & IStateProps & IActionProps
> {
  render() {
    const { variables, transformation, frequency, applyTo, noGrowthRatesAllowed } =
      this.props
    const series = applyTo === 'all' ? variables[0] : variables[applyTo]
    const isRestricted =
      applyTo !== 'all'
        ? series.differenceType
        : variables.find(v => v.differenceType) !== undefined

    return (
      <FunctionsDialog
        onClose={this.props.onClose}
        onSave={this.onFunctionsSave}
        hasPercentOrNegative={isRestricted}
        series={{ databaseId: series.databaseId, seriesId: series.id }}
        transformation={transformation}
        isGlobal={applyTo === 'all'}
        frequency={frequency}
        noGrowthRatesAllowed={noGrowthRatesAllowed}
        canApplySA={canApplySA(variables, applyTo)}
        canApplyConversion={canApplyFXConversion(variables, applyTo)}
      />
    )
  }

  private onFunctionsSave = async (t: ITransformation) => {
    const { variables, applyFunctionTo, applyTo } = this.props

    const applyFunction = async (v: IDataSeries, i: number) =>
      applyFunctionTo(
        rewriteSeries(t, {
          seriesId: v.id,
          databaseId: v.databaseId,
          lag: -v.offset,
        }),
        i,
        false
      )

    track('graph', 'setFunction', {
      name: t.func,
      applyTo: applyTo === 'all' ? 'all' : 'one',
      isNested: isNestedFunction(t),
    })
    if (applyTo === 'all') {
      const promises = variables.filter(v => !hasTrendLine(v)).map(applyFunction)
      await Promise.all(promises)
    } else {
      applyFunction(variables[applyTo], applyTo)
    }
  }
}

export const mapStateToProps = (state: IRootState, { applyTo }: IProps): IStateProps => {
  const { variables } = state.series
  const series = variables[applyTo === 'all' ? 0 : applyTo]
  const noGrowthRatesAllowed = (
    applyTo === 'all' ? variables : [variables[applyTo]]
  ).some(v => v.differenceType)

  let transformation = series.transformation

  if (applyTo === 'all') {
    const transformations = new Set(variables.map(v => v.transformation?.func))
    transformation = transformations.size === 1 ? transformation : null
  }
  const frequency = applyTo === 'all' ? getMinFrequency(variables) : series.frequency

  return {
    transformation,
    variables,
    frequency,
    noGrowthRatesAllowed,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  applyFunctionTo: (
    transformation: ITransformation,
    index: number,
    cancellable?: boolean
  ) => dispatch(updateSeriesOnly(transformation, index, cancellable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FunctionsContainer)
