import * as React from 'react'
import { connect } from 'react-redux'

import * as store from '../../store/Database'
import { IRootState } from '../../store'

import { bindBem } from '../../bem'
import enhanceWithClickOutside from 'react-click-outside'

import { ReactComponent as Arrow } from '../../static/arrow.svg'
import { ReactComponent as Check } from '../../static/check.svg'

import './Options.scss'

interface Option {
  name: string
  value: string
}

interface IProps {
  selectedOption: number
  options: string[] | Option[]
  prefixes?: JSX.Element[]
  defaultValue?: string
  disabled?: boolean
  setSeriesOptions?: (selected: number) => void
  onSelect?: (selected: string | Option) => void
  renderPlaceholder?: boolean
}

interface IState {
  isOpen: boolean
}

export class BaseOptions extends React.Component<IProps, IState> {
  state: IState = { isOpen: false }

  handleClickOutside = () => this.state.isOpen && this.toggle()

  getOptionName = (option: string | Option): string => {
    switch (typeof option) {
      case 'string':
        return option
      case 'object':
        return option.name
      default:
        return option
    }
  }

  getOptionValue = (option: string | Option): string => {
    switch (typeof option) {
      case 'string':
        return option
      case 'object':
        return option.value
      default:
        return option
    }
  }

  render() {
    const {
      options,
      selectedOption,
      prefixes,
      defaultValue,
      disabled,
      renderPlaceholder,
    } = this.props
    const { isOpen } = this.state

    const { block, element } = bindBem('Options')

    if (!options || options.length === 0) {
      return renderPlaceholder ? <div className={block()} /> : null
    }

    const selected = options[selectedOption] || defaultValue

    return (
      <div className={block({ expanded: isOpen, disabled })}>
        <div className={element('Selected')} onClick={disabled ? undefined : this.toggle}>
          <OptionName
            name={this.getOptionName(selected)}
            prefix={prefixes?.[selectedOption]}
          />
          {!disabled && <Arrow className={element('Arrow')} />}
        </div>

        {isOpen && (
          <div className={element('List')}>
            {options.map((option: string | Option, index: number) => (
              <OptionItem
                prefix={prefixes?.[index]}
                isSelected={index === selectedOption}
                onClick={() => this.onClick(option, index)}
                text={this.getOptionName(option)}
                key={this.getOptionValue(option)}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  private onClick = (option: string | Option, index: number) => {
    this.props.setSeriesOptions?.(index)
    this.props.onSelect?.(option)
    this.toggle()
  }

  private toggle = () => this.setState(() => ({ isOpen: !this.state.isOpen }))
}

export const OptionItem = (props: {
  isSelected: boolean
  text: string
  prefix: JSX.Element
  onClick: () => void
}) => {
  const { block, element } = bindBem('OptionItem')
  return (
    <div
      className={block({ isSelected: props.isSelected })}
      key={props.text}
      onClick={props.onClick}
    >
      <OptionName name={props.text} prefix={props.prefix} />
      {props.isSelected && <Check className={element('Check')} />}
    </div>
  )
}

const OptionName = (props: { name: string; prefix: JSX.Element }) => (
  <div className="OptionName">
    {props.prefix}
    {props.name}
  </div>
)

const mapStateToProps = (state: IRootState) => {
  const { selectedSeriesOption, route } = state.databases
  return {
    selectedOption: selectedSeriesOption,
    options:
      route.length > 0 ? route[route.length - 1].options : ([] as string[] | Option[]),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  setSeriesOptions: (selected: number) =>
    dispatch(store.ACTIONS.setSeriesOptions(selected)),
})

export const Options = enhanceWithClickOutside(BaseOptions) as React.FC<IProps>

export default connect(mapStateToProps, mapDispatchToProps)(Options)
