import * as React from 'react'

import { bindBem } from '../bem'
import { ENV, IS_PRODUCTION } from '../config'
import { ReactComponent as OpenAndLeave } from 'static/open-and-leave.svg'
import { ReactComponent as Bell } from 'static/bell.svg'
import { ReactComponent as ChevronLeft } from 'static/chevron-left.svg'

import './SidebarHeader.scss'
import { HOMEPAGE_URL, UPDATES_URL } from '../constants'

export interface IProps {
  isCollapsed?: boolean
  toggleSidebar: () => void
}

export const SidebarHeader = (props: IProps) => {
  const { block, element } = bindBem('SidebarHeader')
  const isCollapsed = props.isCollapsed || false

  return (
    <nav className={`${block({ isCollapsed })} row middle-xs between-xs`}>
      <div className={element('Logo')} />

      {!isCollapsed && !IS_PRODUCTION && <div className={element('EnvBadge')}>{ENV}</div>}

      <div className={element('Buttons')}>
        {!isCollapsed && (
          <>
            <a
              className={element('Button')}
              href={HOMEPAGE_URL}
              target="_blank"
              rel="noreferrer"
            >
              <span>Haver.com</span>
              <OpenAndLeave className={element('ButtonIcon')} />
            </a>
            <a
              className={element('Button')}
              href={UPDATES_URL}
              target="_blank"
              rel="noreferrer"
            >
              <Bell className={element('ButtonIcon')} />
            </a>
          </>
        )}
        <button
          className={`${element('Button')} ${element('Toggle', {
            isCollapsed,
          })}`}
          onClick={props.toggleSidebar}
        >
          <ChevronLeft className={element('ButtonIcon')} />
        </button>
      </div>
    </nav>
  )
}
