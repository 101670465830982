export const MENU_LAYOUT_URL = 'api/merino/v1/menu_layout'
export const HEALTHCHECK_URL = '.well-known/healthcheck'
export const CURRENCIES_URL = 'api/merino/v1/currencies'
export const FAVORITES_URL = 'api/merino/v1/favorites'
export const CORRELATION_URL = 'api/merino/v1/math/correlation'
export const MATH_URL = 'api/merino/v1/math/series'
export const DATABASES_URL = 'api/merino/v1/databases'
export const RECESSION_COUNTRIES_URL = 'api/merino/v1/recessions/countries'
export const RECESSION_URL = 'api/merino/v1/recessions'
export const LOGIN_URL = 'auth/login'
export const LOGOUT_URL = 'auth/logout'
export const ME_URL = 'api/me'
export const LINK_SHARE_URL = 'api/merino/v1/share'
export const REQUEST_PASSWORD_RESET = 'api/reset_password'
export const SERIES_SEARCH_URL = 'api/merino/v1/search'
