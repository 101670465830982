import * as React from 'react'
import { connect } from 'react-redux'

import { IRootState } from '../store'
import Sidebar from '../containers/Sidebar'
import ClientSupport from './ClientSupport'
import { CLIENT_SUPPORT, SHORTCUTS_PANEL, SIDEBAR } from '../messages'
import { ACTIONS } from '../store/Series'
import { ACTIONS as DATABASE_ACTIONS } from '../store/Database'
import { AsideDialog, AsideDialogRoute, Modal } from './Modal'
import { Desktop, Mobile, withResponsive, useIsLandscapeMobile } from './Responsive'
import MobileHeader from './MobileHeader'
import { bindBem } from '../bem'
import { KeyboardShortcutsDialog } from './KeyboardShortcutsDialog'
import KeyboardShortcutHandler from './KeyboardShortcutHandler'
import ScalingOptionsDialog from '../containers/ScalingOptionsDialog'
import { Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom'
import SeriesSearch from './SeriesSearch'
import DataDirectory from './Sidebar/DataDirectory'
import SeriesContainer from 'containers/SeriesContainer'
import FunctionsContainer from 'containers/FunctionsContainer'

import './Dashboard.scss'

export interface IProps {
  isMobile: boolean
}
export interface IStateProps {
  activeModal: SeriesModal
}

export interface IActionProps {
  setActiveModal: (modal: SeriesModal) => void
  loadRouteSnapshot: () => void
  saveRouteSnapshot: () => void
}

export class BaseDashboard extends React.Component<IProps & IStateProps & IActionProps> {
  closeModal = () => this.props.setActiveModal(null)
  openHelp = () => this.props.setActiveModal('help')

  componentDidMount() {
    scrollTo(0, 0)
  }

  render() {
    const { isMobile, activeModal } = this.props
    const { children } = this.props
    const { block, element } = bindBem('Dashboard')
    return (
      <div className={block({ isMobile })}>
        <main className={element('Main')}>{children}</main>
        <AsideDialog
          title={CLIENT_SUPPORT.HEADER}
          isOpen={activeModal === 'help'}
          onClose={this.closeModal}
        >
          <ClientSupport />
        </AsideDialog>
        <AsideDialog
          isOpen={activeModal === 'shortcuts'}
          onClose={this.closeModal}
          title={SHORTCUTS_PANEL.TITLE}
        >
          <KeyboardShortcutsDialog />
        </AsideDialog>
        <AsideDialog
          isOpen={activeModal === 'scalingOptions'}
          onClose={this.closeModal}
          title={'Scaling options'}
        >
          <ScalingOptionsDialog onClose={this.closeModal} />
        </AsideDialog>

        <Modal isOpen={activeModal === 'userProfile'} onClose={this.closeModal}>
          FIXME
        </Modal>
        <KeyboardShortcutHandler />
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState): IStateProps => ({
  activeModal: state.series.activeModal,
})

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  setActiveModal: (modal: SeriesModal) => dispatch(ACTIONS.setActiveModal(modal)),
  loadRouteSnapshot: () => dispatch(DATABASE_ACTIONS.loadRouteSnapshot()),
  saveRouteSnapshot: () => dispatch(DATABASE_ACTIONS.saveRouteSnapshot()),
})

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(BaseDashboard))

export const Main: React.FC = () => {
  const isLandscapeMobile = useIsLandscapeMobile()
  const history = useHistory()
  const location = useLocation<{ background: any }>()
  const background: typeof location = location.state?.background
  const onFunctionsClose = () => history.push(background.pathname)
  return (
    <Dashboard>
      <Desktop>
        <Sidebar />
      </Desktop>

      <Mobile>
        {isLandscapeMobile ? null : <MobileHeader />}
        <AsideDialogRoute
          path="/functions"
          title={SIDEBAR.FUNCTIONS}
          onClose={onFunctionsClose}
        >
          <FunctionsContainer applyTo="all" onClose={onFunctionsClose} />
        </AsideDialogRoute>
      </Mobile>

      <Switch location={background || location}>
        <Route path={['/series/:id', '/series']} component={SeriesContainer} />
        <Route exact path="/">
          <Mobile>
            <SeriesSearch>
              <DataDirectory />
            </SeriesSearch>
          </Mobile>
        </Route>
        <Redirect to="/" />
      </Switch>
    </Dashboard>
  )
}
