import * as React from 'react'
import { bindBem } from '../../bem'

import { ReactComponent as ALetter } from 'static/a-letter.svg'
import { ReactComponent as FLetterSm } from 'static/f-letter-sm.svg'
import { ReactComponent as ILetterSm } from 'static/i-letter-sm.svg'
import { ReactComponent as TLetter } from 'static/t-letter.svg'
import { ReactComponent as CloseSm } from 'static/close-sm.svg'
import { ReactComponent as Undo } from 'static/undo.svg'

import './Buttons.scss'

const { block, element } = bindBem('TableButton')

interface IProps {
  active?: boolean
  disabled?: boolean
  noBorder?: boolean
  graphButton?: boolean
  fxButton?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
  close?: React.ReactNode
  title?: string
}

export const Button = (props: IProps) => {
  const className = block({
    active: props.active,
    noBorder: props.noBorder,
    graph: props.graphButton,
    fx: props.fxButton,
  })
  return (
    <button
      className={className}
      disabled={props.disabled}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}

      {props.onClick && (
        <div className={element('Close')}>{props.close || <CloseSm />}</div>
      )}
    </button>
  )
}

export const FxButton = (props: IProps & { isArithmetic?: boolean }) => (
  <Button fxButton {...props} close={<Undo />} title={props.title}>
    {props.isArithmetic ? <TLetter /> : <FLetterSm />}
  </Button>
)

export const InterpolationButton = (props: any) => (
  <Button {...props}>
    <ILetterSm />
  </Button>
)

export const AggregationButton = (props: any) => (
  <Button {...props}>
    <ALetter />
  </Button>
)
