import * as React from 'react'
import { bindBem } from '../bem'
import { withResponsive } from './Responsive'

import './ToggleButton.scss'

import { ReactComponent as Arrow } from 'static/arrow.svg'

interface IProps {
  onClick: () => void
  isCollapsed?: boolean
  vertical?: boolean
  isMobile?: boolean
}

export const ToggleButton = (props: IProps) => {
  const { block, element } = bindBem('ToggleButton')
  const arrowClassName = props.isCollapsed ? 'ArrowRight' : 'ArrowLeft'
  return (
    <div
      className={block({ vertical: props.vertical, big: props.isMobile })}
      onClick={props.onClick}
    >
      <div className={element(arrowClassName)}>
        <Arrow />
      </div>
    </div>
  )
}

export default withResponsive(ToggleButton)
