import React from 'react'
import { DropdownButton } from './DropdownButton'
import { TransformationButton } from './Buttons'
import { RadioGroup, Option, Separator } from '../RadioGroup'

import { ReactComponent as Add } from 'static/add.svg'
import { ReactComponent as Subtract } from 'static/subtract.svg'
import { ReactComponent as Multiply } from 'static/multiply.svg'
import { ReactComponent as Divide } from 'static/divide.svg'
import { ReactComponent as Percent } from 'static/percent.svg'

export const TYPES: { [key in TransformationType]: JSX.Element } = {
  ADD: <Add />,
  SUBTRACT: <Subtract />,
  MULTIPLY: <Multiply />,
  DIVIDE: <Divide />,
  PERCENT: <Percent />,
}

interface IProps {
  value: TransformationType
  onChange: (type: TransformationType) => void
}

export const TransformationTypeDropdown = ({ value, onChange }: IProps) => {
  const wrapperRef = React.createRef<DropdownButton>()

  const Button = (props: { active: boolean; onClick: () => void }) => (
    <TransformationButton
      icon={value ? TYPES[value] : TYPES.ADD}
      {...props}
      active={true}
    />
  )
  return (
    <DropdownButton buttonRenderer={Button} stickTo="left" ref={wrapperRef}>
      <RadioGroup
        value={value}
        onChange={val => {
          onChange(val as TransformationType)
          wrapperRef.current.togglePortal()
        }}
      >
        <Option value="ADD">{TYPES.ADD}Add</Option>
        <Separator />
        <Option value="SUBTRACT">{TYPES.SUBTRACT}Subtract</Option>
        <Separator />
        <Option value="MULTIPLY">{TYPES.MULTIPLY}Multiply</Option>
        <Separator />
        <Option value="DIVIDE">{TYPES.DIVIDE}Divide</Option>
        <Separator />
        <Option value="PERCENT">{TYPES.PERCENT}Percent</Option>
      </RadioGroup>
    </DropdownButton>
  )
}
