import * as React from 'react'
import { connect } from 'react-redux'

import { IRootState, TDispatch } from '../store'
import { loginStart } from '../store/User'

import { bindBem } from '../bem'

import loginBtnLoader from './../static/loader@2x.gif'

import './LoginForm.scss'
import { useEffect } from 'react'

export interface IProps {
  loginFailed: boolean
  loginPending: boolean
  loginError: string
}

export interface ILoginFormProps extends IProps {
  onLogin: () => void
}

export const LoginForm: React.FC<ILoginFormProps> = props => {
  const { element } = bindBem('LoginForm')

  useEffect(() => {
    if (!props.loginPending) {
      props.onLogin()
    }
  }, [props.loginPending])

  return <img className={element('Loader')} src={loginBtnLoader} alt="Loading..." />
}

export const LoginContainer: React.FC<ILoginFormProps> = props => {
  const { onLogin, loginPending, loginFailed, loginError } = props

  return (
    <LoginForm
      loginError={loginError}
      onLogin={onLogin}
      loginPending={loginPending}
      loginFailed={loginFailed}
    />
  )
}

const mapDispatchToProps = (dispatch: TDispatch): Partial<ILoginFormProps> => {
  return {
    onLogin: () => dispatch(loginStart()),
  }
}

const mapStateToProps = ({
  user: { loginError, loginFailed, loginPending },
}: IRootState): Partial<ILoginFormProps> => {
  return {
    loginFailed,
    loginPending,
    loginError,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
