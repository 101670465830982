import * as menus from '../api/menu'
import { createAction, ActionType, getType } from 'typesafe-actions'
import { TDispatch } from '.'
import { IMenuEntry, IMenuLayout } from 'global'

export const ACTIONS = {
  setMenu: createAction('SET_MENU', resolve => (menu: IMenuLayout) => resolve(menu)),
}

export type State = {
  entry: IMenuLayout
}

const INITIAL_STATE = (): State => ({
  entry: null,
})

export type MenusActions = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>

export function reducer(state = INITIAL_STATE(), action: MenusActions): State {
  switch (action.type) {
    case getType(ACTIONS.setMenu):
      return { ...state, entry: { ...action.payload } }
    default:
      return state
  }
}

export function fetchMenu() {
  return async (dispatch: TDispatch) => {
    const menu = await menus.fetchMenuLayout()
    const filteredLayouts = menu.layout.reduce(filterMenuLayout, [])

    dispatch(
      ACTIONS.setMenu({
        ...menu,
        layout: filteredLayouts,
      })
    )
  }
}

function filterMenuLayout(
  prevValue: IMenuEntry[],
  current: IMenuEntry,
  index: number,
  children: IMenuEntry[]
): IMenuEntry[] {
  if (current.type === 'db' && current.root_menu_page_name === null) {
    return removeDbEntryAndFollowingSeparator(prevValue, index, children)
  }

  if ('children' in current) {
    const filteredEntryChildren = current.children.reduce(filterMenuLayout, [])

    return [...prevValue, { ...current, children: filteredEntryChildren }]
  }

  return [...prevValue, current]
}

function removeDbEntryAndFollowingSeparator(
  prevValue: IMenuEntry[],
  index: number,
  array: IMenuEntry[]
): IMenuEntry[] {
  const nextElementIndex = index + 1
  const nextElement = array[nextElementIndex]
  if (nextElement !== undefined && nextElement.type === 'separator') {
    array.splice(nextElementIndex, 1)
  }

  return prevValue
}
