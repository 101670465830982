import React from 'react'
import { DropdownButton } from './DropdownButton'
import { GraphButton } from './Buttons'
import { RadioGroup, Option, Separator } from '../RadioGroup'
import { TOOLTIPS } from '../../messages'

import { ReactComponent as Line } from 'static/line.svg'
import { ReactComponent as Bar } from 'static/bar.svg'
import { ReactComponent as ShadedBar } from 'static/shaded-bar.svg'
import { ReactComponent as StepLine } from 'static/step-line.svg'
import { ReactComponent as Area } from 'static/area.svg'
import { ReactComponent as DottedLine } from 'static/dotted-line.svg'
import { ReactComponent as DashedLine } from 'static/dashed-line.svg'
import { ReactComponent as StackedBar } from 'static/stacked-bar.svg'
import { ReactComponent as ShadedStackedBar } from 'static/shaded-stacked-bar.svg'
import { ReactComponent as Scatter } from 'static/scatter.svg'

const TYPES: { [key in ISeriesType]: JSX.Element } = {
  LINE: <Line />,
  DOTTEDLINE: <DottedLine />,
  DASHEDLINE: <DashedLine />,
  AREA: <Area />,
  BAR: <Bar />,
  SHADED_BAR: <ShadedBar />,
  STEPLINE: <StepLine />,
  STACKEDBAR: <StackedBar />,
  SHADED_STACKEDBAR: <ShadedStackedBar />,
  SCATTER: <Scatter />,
}

const BUTTON_TYPES: { [key in ISeriesType]: JSX.Element } = {
  ...TYPES,
  SHADED_BAR: <ShadedBar />,
  SHADED_STACKEDBAR: <ShadedStackedBar />,
}

interface IProps {
  value: ISeriesType
  onChange: (type: ISeriesType) => void
  onlyLines?: boolean
  stackedBarDisabled?: boolean
  scatterDisabled?: boolean
  disabled?: boolean
  title?: string
}

export const GraphTypeDropdown = ({
  value,
  onChange,
  onlyLines,
  stackedBarDisabled,
  scatterDisabled,
  disabled,
  title,
}: IProps) => {
  const wrapperRef = React.createRef<DropdownButton>()

  const Button = (props: { active: boolean; onClick: () => void }) => (
    <GraphButton
      icon={value ? BUTTON_TYPES[value] : BUTTON_TYPES.LINE}
      {...props}
      disabled={disabled}
    />
  )

  return (
    <DropdownButton
      buttonRenderer={Button}
      stickTo="left"
      ref={wrapperRef}
      title={title || TOOLTIPS.GRAPH_TYPE}
    >
      <RadioGroup
        value={value}
        onChange={val => {
          onChange(val as ISeriesType)
          wrapperRef.current.togglePortal()
        }}
      >
        <Option value="LINE">{TYPES.LINE}Line</Option>
        <Option value="DOTTEDLINE">{TYPES.DOTTEDLINE}Line dotted</Option>
        <Option value="DASHEDLINE">{TYPES.DASHEDLINE}Line dashed</Option>
        {!onlyLines && (
          <>
            <Separator />
            <Option value="AREA">{TYPES.AREA}Area Graph</Option>
            <Separator />
            {!scatterDisabled && (
              <>
                <Option value="SCATTER">{TYPES.SCATTER}Scatter Graph</Option>
                <Separator />
              </>
            )}
            <Option value="STEPLINE">{TYPES.STEPLINE}Step Lines</Option>
            <Separator />
            <Option value="BAR">{TYPES.BAR}Bar Graph</Option>
            <Option value="SHADED_BAR">{TYPES.SHADED_BAR}Shaded Bar Graph</Option>
            <Separator />
            <Option value="STACKEDBAR" disabled={stackedBarDisabled}>
              {TYPES.STACKEDBAR}Stacked Graph
            </Option>
            <Option value="SHADED_STACKEDBAR" disabled={stackedBarDisabled}>
              {TYPES.SHADED_STACKEDBAR}Shaded Stacked Graph
            </Option>
          </>
        )}
      </RadioGroup>
    </DropdownButton>
  )
}
