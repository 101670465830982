import * as React from 'react'

import { bindBem } from 'bem'

import './DataUpdatesTab.scss'
import { UPDATES_URL } from '../../constants'
import { SIDEBAR } from '../../messages'

const DataUpdatesTab = () => {
  const { block, element } = bindBem('DataUpdatesTab')

  return (
    <div className={block()}>
      <div className={element('Title')}>{SIDEBAR.DATA_UPDATES_TAB.TITLE}</div>
      <a className={element('Link')} href={UPDATES_URL} target="_blank" rel="noreferrer">
        {SIDEBAR.DATA_UPDATES_TAB.LINK}
      </a>
    </div>
  )
}

export default DataUpdatesTab
