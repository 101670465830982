import * as React from 'react'
import * as Sentry from '@sentry/react'

import DataDirectory from '../components/Sidebar/DataDirectory'
import { SidebarHeader } from '../components/SidebarHeader'
import { SidebarFooter } from '../components/SidebarFooter'
import { TABS } from '../services/sidebar'
import SeriesTab from '../components/Sidebar/SeriesTab'
import { connect } from 'react-redux'
import { IRootState } from '../store'
import { bindBem } from '../bem'
import { ACTIONS } from '../store/Series'
import Menu from '../components/Sidebar/Menu'
import { DragAndDropTooltip } from '../components/DragAndDropTooltip'
import { isDragAndDropTooltipVisible, hideDragAndDropTooltip } from '../services/user'
import SeriesSearch from '../components/SeriesSearch'
import MenuToolbar from '../components/MenuToolbar'

import './Sidebar.scss'
import DataUpdatesTab from '../components/Sidebar/DataUpdatesTab'

export interface IProps {
  selectedTab: TABS
  variables: IDataSeries[]
}

export interface IActionProps {
  setActiveModal: (modal: SeriesModal) => void
}

export interface IState {
  selectedTab: number
  isCollapsed: boolean
  dragAndDropTooltipVisible: boolean
}

export class Sidebar extends React.Component<IProps & IActionProps> {
  state: IState = {
    isCollapsed: false,
    selectedTab: TABS.DIRECTORY,
    dragAndDropTooltipVisible: isDragAndDropTooltipVisible(),
  }

  toggleSidebar = () => this.setState({ isCollapsed: !this.state.isCollapsed })
  openHelp = () => this.props.setActiveModal('help')
  openShortcuts = () => this.props.setActiveModal('shortcuts')

  renderOpen() {
    const { selectedTab, variables } = this.props
    const displayTooltip = this.state.dragAndDropTooltipVisible && variables.length > 1
    const { block } = bindBem('Sidebar')

    return (
      <div className={block({ isCollapsed: this.state.isCollapsed })}>
        <SidebarHeader toggleSidebar={this.toggleSidebar} />
        <Menu />

        {selectedTab === TABS.DIRECTORY && (
          <SeriesSearch>
            <DataDirectory />
          </SeriesSearch>
        )}
        {selectedTab === TABS.SERIES && (
          <>
            <MenuToolbar />
            {displayTooltip && (
              <DragAndDropTooltip hideDragAndDropTooltip={this.hideDragAndDropTooltip} />
            )}
            <SeriesTab />
          </>
        )}
        {selectedTab === TABS.DATA_UPDATES && <DataUpdatesTab />}

        <SidebarFooter
          onHelpClick={this.openHelp}
          onShortcutsClick={this.openShortcuts}
        />
      </div>
    )
  }

  renderCollapsed() {
    const { block } = bindBem('Sidebar')
    const { isCollapsed } = this.state

    return (
      <div className={block({ isCollapsed })}>
        <SidebarHeader isCollapsed={isCollapsed} toggleSidebar={this.toggleSidebar} />
        <Menu isCollapsed={isCollapsed} />
        <MenuToolbar isCollapsed={isCollapsed} />
      </div>
    )
  }

  render() {
    return this.state.isCollapsed ? this.renderCollapsed() : this.renderOpen()
  }

  private hideDragAndDropTooltip = () => {
    this.setState({ ...this.state, dragAndDropTooltipVisible: false })
    hideDragAndDropTooltip()
  }
}

const mapStateToProps = (state: IRootState): IProps => {
  const { variables } = state.series
  const { sidebarTab } = state.databases
  return {
    selectedTab: sidebarTab,
    variables,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    setActiveModal: (modal: SeriesModal) => dispatch(ACTIONS.setActiveModal(modal)),
  }
}

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
