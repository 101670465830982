import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { GRAPH } from '../../messages'
import { BASE_FONT } from '../../constants'
import { getDatesExtent } from '../../services/table'
import { formatLabel } from '../../services/series'

export interface IProps {
  right: number
  top: number
  variables: IDataSeries[]
}

const renderScatterInfo = (
  svg: D3Selection,
  right: number,
  top: number,
  variables: IDataSeries[]
) => {
  const extents = getDatesExtent(variables)
  const [start, end] = extents
  const freq = variables[0].frequency
  svg
    .append('text')
    .attr('text-anchor', 'end')
    .style('font-size', 11)
    .attr('fill', '#788596')
    .style('font-family', BASE_FONT)
    .text(GRAPH.SCATTER_RANGE(formatLabel(start, freq), formatLabel(end, freq)))
    .attr('x', right)
    .attr('y', top - 14)
}

const ScatterInfo = ({ right, top, variables }: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)

  renderScatterInfo(selection, right, top, variables)
  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default ScatterInfo
