import { SIDEBAR } from '../messages'

export enum TABS {
  DIRECTORY,
  SERIES,
  DATA_UPDATES,
}

export const BUTTONS = [
  { name: SIDEBAR.DIRECTORY, tab: TABS.DIRECTORY },
  { name: SIDEBAR.SERIES, tab: TABS.SERIES },
  { name: SIDEBAR.DATA_UPDATES, tab: TABS.DATA_UPDATES },
]
