import React, { useEffect, useState } from 'react'

import { bindBem } from '../../bem'
import { Button } from '../Button'

import './DataDirectoryCollapseGroup.scss'

function setElementHeight(el: HTMLElement, closed: boolean) {
  el.style.height = closed ? '0px' : null
}

const CollapseGroup: React.FC<{ collapsed: boolean }> = ({ children, collapsed }) => {
  const { block, element } = bindBem('DataDirectoryCollapseGroup')
  const ref = React.useRef()

  const [collapseState, setCollapseState] = useState(collapsed)

  const toggleCollapsed = () => {
    setCollapseState(prev => !prev)
  }

  useEffect(() => {
    if (ref.current) {
      setElementHeight(ref.current, collapseState)
    }
  }, [collapseState])

  useEffect(() => ref.current && setElementHeight(ref.current, collapseState), [ref])

  return (
    <div className={block({ isCollapsed: collapseState })}>
      {children && (
        <Button
          icon={<div className={element('Chevron')} />}
          size="small"
          onClick={toggleCollapsed}
          className={element('Button')}
        />
      )}
      <div ref={ref} className={element('Inner')}>
        {!collapseState && children}
      </div>
    </div>
  )
}

export default CollapseGroup
