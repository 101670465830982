// eslint-disable max-lines

import * as React from 'react'
import * as Sentry from '@sentry/react'
import { connect } from 'react-redux'
import { IRootState } from '../../store'

import DataDirectoryHeader from './DataDirectoryHeader'
import { DataDirectoryEntry } from './DataDirectoryEntry'
import { bindBem } from '../../bem'
import * as store from '../../store/Database'
import { ACTIONS } from '../../store/Series'
import { Modal } from '../Modal'
import { FootnoteDialog } from './FootnoteDialog'
import { getDbSeriesId } from '../../utils'
import { DATA_DIRECTORY_BANNER, SIDEBAR } from '../../messages'
import { EMAIL_ADDRESS } from '../../constants'
import { withResponsive } from '../Responsive'

import * as ga from '../../analytics'
import CollapseGroup from './DataDirectoryCollapseGroup'
import ScrollRetainer from './ScrollRetainer'
import Loader from '../../containers/SeriesContainer/Loader'
import { convertMenuLayout } from '../../services/menu'

import './DataDirectory.scss'

export const DataDirectorySection = (props: {
  label: string
  children: JSX.Element[]
}) => {
  const { block, element } = bindBem('DataDirectorySection')
  return (
    <div className={block()}>
      <div className={element('Title')}>{props.label}</div>
      <div className={element('Children')}>{props.children}</div>
    </div>
  )
}

export interface IProps {
  replaceIndex?: number
  isMobile?: boolean
  link?: ILink
}

export interface IStateProps {
  entries: IDirectoryEntry[]
  dbs: IDirectoryEntry[]
  route: IDirectoryEntry[]
  openedDbSeriesId: string[]
  footnotes: SMap<IFootnote>
  selectedSeriesOption: number
  loadingSeriesId: string
  variablesCount: number
  dbConfigName: string
  starredDatabases: Set<string>
  lastIndex: number
  menu: IMenuLayout
  pending: boolean
}

export interface IState {
  isFootnoteOpen: boolean
  footnoteId: string
  footnoteTitle: string
  showStarred: boolean
}

export interface IActionProps {
  pushEntry: (entry: IDirectoryEntry, index?: number) => void
  popEntries: (i: number) => void
  setActiveModal: (modal: SeriesModal) => void
  resetGraph: () => void
  starDatabase: (dbName: string) => void
  unstarDatabase: (dbName: string) => void
}

export type Props = IStateProps & IActionProps & IProps

export class DataDirectory extends React.Component<Props, IState> {
  state: IState = {
    isFootnoteOpen: false,
    footnoteId: null,
    footnoteTitle: null,
    showStarred: false,
  }

  render() {
    const { footnoteId, footnoteTitle, isFootnoteOpen } = this.state
    const { block, element } = bindBem('DataDirectory')
    const { route, popEntries, footnotes, entries, pending } = this.props

    const parent = route[route.length - 1]
    const isTopLevel = route.length === 0
    const renderedEntries = isTopLevel
      ? this.renderDatabases()
      : entries.map((entry, i) => this.renderDirectoryEntry(entry, 0, i))

    return (
      <div className={block()}>
        <div className={element('Title')}>{SIDEBAR.DATA_DIRECTORY}</div>
        {!isTopLevel && (
          <DataDirectoryHeader
            entries={route}
            onFootnoteClick={() =>
              this.openFootnote(parent.id, parent.footnoteId, parent.description)
            }
            onNavigateBack={i => popEntries(i)}
          />
        )}
        {isTopLevel && (
          <Tabs>
            <Tab
              active={!this.state.showStarred}
              onClick={() => this.setShowStarred(false)}
            >
              {SIDEBAR.DATA_DIRECTORY_ALL}
            </Tab>
            <Tab
              active={this.state.showStarred}
              onClick={() => this.setShowStarred(true)}
            >
              {SIDEBAR.DATA_DIRECTORY_STARRED}
            </Tab>
          </Tabs>
        )}

        <div className={element('ContentWrapper')}>
          <Loader loading={pending} />
          <ScrollRetainer className={element('Content')} route={route.map(r => r.link)}>
            {renderedEntries}
            {parent?.isPreview && <div className={element('Spacer')} />}
          </ScrollRetainer>
        </div>
        {parent?.isPreview && <PreviewBanner />}
        <Modal isOpen={isFootnoteOpen} onClose={this.onClose}>
          <FootnoteDialog
            footnoteId={footnoteId}
            title={footnoteTitle}
            text={footnotes?.[footnoteId]?.description}
            onClose={this.onClose}
          />
        </Modal>
      </div>
    )
  }

  private renderDatabases = () => {
    const { menu, dbs } = this.props

    if (!menu || dbs.length === 0) {
      return null
    }

    const databases = this.state.showStarred
      ? dbs.filter(db => this.isStarred(db.id))
      : dbs
    const directoryEntries = convertMenuLayout(databases, menu, this.state.showStarred)
    return directoryEntries.map((entry, i) => this.renderDirectoryEntry(entry, 0, i))
  }

  private onClose = () => this.setState(() => ({ isFootnoteOpen: false }))

  private setShowStarred = (showStarred: boolean) => this.setState({ showStarred })

  private onEntryClick = (entry: IDirectoryEntry) => () => {
    const {
      pushEntry,
      replaceIndex,
      lastIndex: index,
      isMobile,
      setActiveModal,
      resetGraph,
      link,
    } = this.props

    if (isMobile && store.isSeries(entry) && link) {
      resetGraph()
    }

    pushEntry(entry, replaceIndex ?? index)

    if (isMobile && store.isSeries(entry)) {
      setActiveModal(null)
    }
  }

  private renderDirectoryEntry(
    entry: IDirectoryEntry,
    level = 0,
    index = 0
  ): JSX.Element {
    if (!entry) {
      return null
    }
    const { route, selectedSeriesOption, openedDbSeriesId } = this.props
    const { loadingSeriesId, variablesCount, isMobile } = this.props

    const disabled = entry.links && !entry.links[selectedSeriesOption]
    const parent = route[route.length - 1]
    const dbSeriesId = route.length > 0 && `${entry.id}@${route[0].id}`
    const key = `${entry.id}-${index}`

    let onSecondaryClick: React.EventHandler<React.MouseEvent> =
      entry.type === 'series' ? this.onSeriesSecondaryClick(entry) : undefined

    if (entry.type === 'db') {
      onSecondaryClick = this.isStarred(entry.id)
        ? this.onUnstarDatabase(entry)
        : this.onStarDatabase(entry)
    }

    const renderChildren = () =>
      entry.children.map((child, i) => this.renderDirectoryEntry(child, level + 1, i))

    return (
      <React.Fragment key={key}>
        <DataDirectoryEntry
          key={key}
          isDisabled={disabled}
          isSelected={
            openedDbSeriesId.includes(dbSeriesId) ||
            (!isMobile && this.isStarred(entry.id))
          }
          isLoading={loadingSeriesId === dbSeriesId}
          entry={entry}
          level={level}
          onClick={this.onEntryClick(entry)}
          onSecondaryClick={onSecondaryClick}
          onFootnoteClick={() =>
            this.openFootnote(entry.link, entry.footnoteId, entry.description)
          }
          isPreview={parent?.isPreview}
          variablesCount={variablesCount}
          isMobile={isMobile}
        />
        {entry.children &&
          (entry.collapsable && !this.state.showStarred ? (
            <CollapseGroup collapsed={!entry.expanded}>{renderChildren()}</CollapseGroup>
          ) : (
            renderChildren()
          ))}
      </React.Fragment>
    )
  }

  private isStarred = (dbName: string) => this.props.starredDatabases.has(dbName)

  private onSeriesSecondaryClick: (
    entry: IDirectoryEntry
  ) => React.EventHandler<React.MouseEvent> = entry => e => {
    e.stopPropagation()
    this.props.pushEntry(entry)
  }

  private onStarDatabase: (
    entry: IDirectoryEntry
  ) => React.EventHandler<React.MouseEvent> = entry => e => {
    e.stopPropagation()
    this.props.starDatabase(entry.id)
  }

  private onUnstarDatabase: (
    entry: IDirectoryEntry
  ) => React.EventHandler<React.MouseEvent> = entry => e => {
    e.stopPropagation()
    this.props.unstarDatabase(entry.id)
  }

  private openFootnote = (id: string, footnoteId: string, footnoteTitle: string) => {
    const { route } = this.props
    ga.track('data directory', 'open footnote', {
      footnoteId,
      series: id,
      db: route[0].link,
    })
    this.setState(() => ({ footnoteId, footnoteTitle, isFootnoteOpen: true }))
  }
}

export const PreviewBanner = () => {
  const { block, element } = bindBem('PreviewBanner')
  return (
    <div className={block()}>
      <div className={element('Main')}>{DATA_DIRECTORY_BANNER.MAIN_TITLE}</div>
      <div className={element('Sub')}>{DATA_DIRECTORY_BANNER.EMAIL_US}</div>
      <a className={element('Email')} href={`mailto:${EMAIL_ADDRESS}`}>
        {EMAIL_ADDRESS}
      </a>
    </div>
  )
}

export const Tabs: React.FC = props => (
  <div className="DataDirectoryTabs">{props.children}</div>
)

export const Tab: React.FC<{
  active: boolean
  onClick: () => void
}> = props => {
  const { block } = bindBem('DataDirectoryTab')
  return (
    <div className={block({ active: props.active })} onClick={props.onClick}>
      {props.children}
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { entries, dbs, route, footnotes, selectedSeriesOption, favouriteDbs, pending } =
    state.databases
  const { variables, loadingSeriesId } = state.series
  const { user } = state.user
  const { entry: menu } = state.menu
  return {
    pending,
    loadingSeriesId,
    entries,
    dbs,
    route,
    menu,
    openedDbSeriesId: variables ? variables.map(series => getDbSeriesId(series)) : [],
    footnotes,
    selectedSeriesOption,
    variablesCount: variables.length,
    dbConfigName: user.dbConfigName,
    starredDatabases: favouriteDbs,
    lastIndex: variables.length > 0 ? variables.length - 1 : 0,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  pushEntry: (entry: IDirectoryEntry, index?: number) =>
    dispatch(store.pushEntry(entry, index)),
  popEntries: (i: number) => dispatch(store.popEntry(i)),
  setActiveModal: (modal: SeriesModal) => dispatch(ACTIONS.setActiveModal(modal)),
  resetGraph: () => dispatch(ACTIONS.resetGraph()),
  starDatabase: name => dispatch(store.createFavourite(name)),
  unstarDatabase: name => dispatch(store.deleteFavourite(name)),
})

export default Sentry.withProfiler(
  connect(mapStateToProps, mapDispatchToProps)(withResponsive(DataDirectory))
)
