// eslint-disable max-lines
import * as ga from 'analytics'
import * as API from 'api/series'
import {
  getCorrelation,
  getMaths,
  getRecessions,
  getSeries as fetchSeries,
} from 'api/series'
import { getZoomRange } from 'services/hd3'
import {
  getAggregation,
  getSeriesLabel,
  getTransformationLabel,
  hasTrendLine,
  hasZScore,
  isFrequencyMismatch,
  isSeries,
} from 'services/series'
import { IRootState, TDispatch } from 'store'
import { toUTC } from 'utils'
import { v4 } from 'uuid'

import { MAX_VARIABLES } from '../../constants'
import { API_ERRORS } from '../../messages'
import {
  getOffset,
  isTransformation,
  seriesToTransformation,
  stripFunctions,
} from '../Transformations'
import { openToast } from '../User'
import { RequestError } from './../../api/config'
import { ACTIONS } from './actions'
import { State } from './reducer'
import {
  Aggregation,
  AxisAssignment,
  IDataSeries,
  IScale,
  ISingleSeries,
  IToast,
  ITransformation,
  TransformationOrSeries,
  Zoom,
} from 'global'

export const getSeriesBySearch =
  (databaseId: string, seriesId: string, append = false, index?: number) =>
  async (dispatch: any) => {
    ga.track('search', 'open series', { series: seriesId, db: databaseId })
    const series = { databaseId, seriesId }
    dispatch(append ? addSeries(series) : replaceSeries(series, index))
  }

const getSeriesOrTransformation = async (
  variable: ISingleSeries | ITransformation,
  aggregation: Aggregation,
  interpolation = false,
  state: State,
  cancellable: boolean
) => {
  const series =
    isSeries(variable) && !interpolation && !aggregation
      ? await getSeries(variable, cancellable)
      : await getTRansformation(
          variable as ITransformation,
          aggregation,
          interpolation,
          state,
          cancellable
        )

  series.offset = getOffset(variable)
  series.uuid = v4()

  return series
}

const getTRansformation = async (
  variable: ITransformation,
  aggregation: Aggregation,
  interpolation = false,
  state: State,
  cancellable: boolean
) => {
  const range = getZoomRange(
    state.variables,
    state.seriesSettings.endZoomDate,
    state.seriesSettings.graphZoom
  )
  const series = await getMaths(
    variable,
    aggregation,
    interpolation,
    cancellable,
    ...range
  )

  if (isTransformation(variable)) {
    series.transformation = variable as ITransformation
  }

  series.title = getSeriesLabel(series)

  return series
}

const getSeries = async (
  { databaseId, seriesId }: ISingleSeries,
  cancellable: boolean
) => {
  const series = await fetchSeries(databaseId, seriesId, cancellable)
  series.title = series.description
  return series
}

async function withLoadingSeries(
  dispatch: any,
  variable: ISingleSeries | ITransformation,
  func: () => Promise<IDataSeries | void>
): Promise<IDataSeries | void> {
  dispatch(ACTIONS.setLoadingSeriesId(getTransformationLabel(variable, 'postfix')))

  return func()
    .then(series => {
      dispatch(ACTIONS.setLoadingSeriesId(null))
      return series
    })
    .catch(error => {
      // if request was canceled
      if (error?.__CANCEL__) {
        return
      }
      throw error
    })
    .catch(error => {
      dispatch(ACTIONS.setLoadingSeriesId(null))
      // handle mostly by using response status and received data
      dispatch(handleSeriesResponseException(error))
      return
    })
}

export const replaceSeries =
  (
    variable: ISingleSeries | ITransformation,
    index: number,
    aggregation?: Aggregation,
    interpolation = false,
    skipCorrUpdate = false,
    cancellable = true
  ) =>
  async (dispatch: TDispatch, getState: () => IRootState) => {
    return withLoadingSeries(dispatch, variable, async () => {
      const allSeries = getState().series
      const insertAt =
        index !== undefined ? index : Math.max(0, allSeries.variables.length - 1)

      const series = await getSeriesOrTransformation(
        variable,
        aggregation,
        interpolation,
        allSeries,
        cancellable
      )

      if (insertAt < MAX_VARIABLES) {
        dispatch(ACTIONS.setSeries(series, insertAt))
      }
      if (!skipCorrUpdate) {
        dispatch(fetchCorrelation())
      }
      return series
    })
  }

export const addSeries =
  (
    variable: ISingleSeries | ITransformation,
    index?: number,
    aggregation?: Aggregation,
    interpolation = false,
    skipCorrUpdate = false,
    attributes: Partial<IDataSeries> = {},
    cancellable = true
  ) =>
  async (dispatch: TDispatch, getState: () => IRootState) => {
    await dispatch(ACTIONS.setActiveModal(null))

    return withLoadingSeries(dispatch, variable, async () => {
      const allSeries = getState().series
      const insertAt =
        index !== undefined ? index : Math.max(0, allSeries.variables.length)

      const series = {
        ...(await getSeriesOrTransformation(
          variable,
          aggregation,
          interpolation,
          allSeries,
          cancellable
        )),
        ...attributes,
      }

      if (insertAt < MAX_VARIABLES) {
        dispatch(ACTIONS.addVariable(series, insertAt))
      }
      if (!skipCorrUpdate) {
        dispatch(fetchCorrelation())
      }
      return series
    })
  }

const handleSeriesResponseException =
  (error: RequestError) => async (dispatch: TDispatch) => {
    const toast: Partial<IToast> = { type: 'error' }

    switch (error?.response?.status) {
      case 404: {
        dispatch(
          openToast({
            ...toast,
            title: API_ERRORS.SERIES.NOT_FOUND.TITLE,
            subtitle: API_ERRORS.SERIES.NOT_FOUND.SUBTITLE,
          })
        )
        break
      }
      case 500: {
        dispatch(
          openToast({
            ...toast,
            title: API_ERRORS.SERIES.GENERAL.TITLE,
            subtitle: API_ERRORS.SERIES.GENERAL.SUBTITLE,
          })
        )
        break
      }
      case 401: {
        dispatch(
          openToast({
            ...toast,
            title: API_ERRORS.SERIES.PERMISSIONS.TITLE,
            subtitle: API_ERRORS.SERIES.PERMISSIONS.SUBTITLE,
          })
        )
        break
      }
      case 422: {
        if (error?.response?.data?.description === 'corr.before-min') {
          dispatch(
            openToast({
              ...toast,
              title: API_ERRORS.SERIES.CORRELATION_BEFORE_MIN.TITLE,
              subtitle: API_ERRORS.SERIES.CORRELATION_BEFORE_MIN.SUBTITLE,
            })
          )
        }

        if (error?.response?.data?.description === 'corr.incorrect-range') {
          dispatch(
            openToast({
              ...toast,
              title: API_ERRORS.SERIES.CORRELATION_INCORRECT_RANGE.TITLE,
              subtitle: API_ERRORS.SERIES.CORRELATION_INCORRECT_RANGE.SUBTITLE,
            })
          )
        }

        if (error?.response?.data?.description === 'sa.incorrect-range') {
          dispatch(
            openToast({
              ...toast,
              title: API_ERRORS.SERIES.SA_INCORRECT_RANGE.TITLE,
              subtitle: API_ERRORS.SERIES.SA_INCORRECT_RANGE.SUBTITLE,
            })
          )
        }

        if (error?.response?.data?.description === 'trdln.before-min') {
          dispatch(
            openToast({
              ...toast,
              title: API_ERRORS.SERIES.TRDLN_BEFORE_MIN.TITLE,
              subtitle: API_ERRORS.SERIES.TRDLN_BEFORE_MIN.SUBTITLE,
            })
          )
        }
        break
      }
      default: {
        throw error
      }
    }
  }

export const loadRecessionCountriesList = () => async (dispatch: any) => {
  const countries = await API.getRecessionCountries()
  dispatch(ACTIONS.setRecessionCountryList(countries))
}

export const setLoadRecessionCountry =
  (country: string | undefined) => async (dispatch: any, getState: () => IRootState) => {
    const { recessionsByCountry } = getState().series
    if (country && !recessionsByCountry[country]) {
      const recessions = await getRecessions(country)
      recessionsByCountry[country] = recessions
      dispatch(ACTIONS.setRecessions(recessionsByCountry))
    }

    dispatch(ACTIONS.setRecessionCountry(country))
  }

export const toggleLegend = () => {
  return (dispatch: TDispatch, getState: () => IRootState) => {
    const { isLegendShown } = getState().series.seriesSettings
    dispatch(ACTIONS.setLegend(!isLegendShown))
  }
}

export const unfreezeSeries =
  (index: number) => async (dispatch: any, getState: () => IRootState) => {
    const series = getState().series.variables[index]
    const singleSeries: ISingleSeries = {
      databaseId: series.databaseId,
      seriesId: series.id,
    }
    const db = getState().databases.dbs.find(d => d.id === series.databaseId)
    if (db?.isEnabled) {
      dispatch(updateSeriesOnly(series.transformation || singleSeries, index))
    } else {
      dispatch(
        openToast({
          title: API_ERRORS.DATABASES.GENERAL.TITLE,
          type: 'error',
        })
      )
    }
  }

export const updateSeriesOnly =
  (transformation: TransformationOrSeries, index: number, cancellable = true) =>
  async (dispatch: any, getState: () => IRootState) => {
    const variable = getState().series.variables[index]
    const aggregation = getAggregation(variable)
    const interpolation = variable.isInterpolated

    return dispatch(
      updateSeries(transformation, index, aggregation, interpolation, cancellable)
    )
  }

export const updateSeries =
  (
    transformation: TransformationOrSeries,
    index: number,
    aggregation: Aggregation,
    interpolation: boolean,
    cancellable = true
  ) =>
  async (dispatch: TDispatch) => {
    const proceedWithTransformation =
      !isSeries(transformation) && transformation.func === 'LEVEL'
        ? stripFunctions(transformation)
        : transformation

    return dispatch(
      replaceSeries(
        proceedWithTransformation,
        index,
        aggregation,
        interpolation,
        false,
        cancellable
      )
    )
  }

export type AsyncAction = (dispatch: any, getState: () => IRootState) => void

export const setZoom =
  (zoom: Zoom): AsyncAction =>
  async dispatch => {
    await dispatch(ACTIONS.setGraphZoom(zoom))
    await dispatch(updateTimeSpanFunctions())
  }

export const updateTimeSpanFunctions = (): AsyncAction => async (dispatch, getState) => {
  const { variables } = getState().series
  await Promise.all(
    variables.map((v, i) =>
      hasTrendLine(v) || hasZScore(v) ? dispatch(updateTimeSpanSeries(v, i)) : null
    )
  )
  await dispatch(fetchCorrelation())
}

const updateTimeSpanSeries =
  (series: IDataSeries, i: number): AsyncAction =>
  async (dispatch, getState) => {
    const newSeries = await dispatch(
      replaceSeries(
        seriesToTransformation(series),
        i,
        getAggregation(series),
        series.isInterpolated,
        true
      )
    )

    if (!newSeries) {
      return
    }

    if (hasTrendLine(series)) {
      const boundaries = getState().series.trendlineBoundaries[series.uuid]
      await dispatch(ACTIONS.setTrendlineBoundary(newSeries.uuid, boundaries))
      await dispatch(ACTIONS.deleteTrendlineBoundary(series.uuid))
    }
  }

export const fetchCorrelation = (): AsyncAction => async (dispatch, getState) => {
  const { correlation, variables } = getState().series
  if (!correlation.enabled) {
    return
  }

  const [v1, v2] = variables
  if (!v1 || !v2) {
    return
  }

  const state = getState().series
  const [start, end] = getZoomRange(
    state.variables,
    state.seriesSettings.endZoomDate,
    state.seriesSettings.graphZoom
  ).map(toUTC)

  try {
    const value = await getCorrelation(
      seriesToTransformation(v1),
      seriesToTransformation(v2),
      start,
      end
    )
    dispatch(ACTIONS.setCorrelationValue(value))
  } catch (e) {
    dispatch(ACTIONS.setCorrelationValue(null))
    dispatch(handleSeriesResponseException(e))
  }
}

export const toggleCorrelation =
  (enabled: boolean): AsyncAction =>
  async (dispatch, getState: () => IRootState) => {
    await dispatch(ACTIONS.toggleCorrelation(enabled))
    const { variables } = getState().series
    const freqMismatch =
      variables.length > 1 &&
      isFrequencyMismatch(variables[0].frequency, variables[1].frequency)
    if (enabled && !freqMismatch) {
      dispatch(fetchCorrelation())
    }
  }

export const setScallingOptions =
  (scale: IScale, assignments: AxisAssignment[]): AsyncAction =>
  async (dispatch, getState: () => IRootState) => {
    const { initialScallingOptions, scale: originalScale, variables } = getState().series

    if (!initialScallingOptions) {
      dispatch(
        ACTIONS.setInitialScallingOptions(
          originalScale,
          variables.map(v => v.axisAssignment)
        )
      )
    }

    dispatch(ACTIONS.setScale(scale))
    assignments.map((a, i) => dispatch(ACTIONS.setSeriesAxisAssignment(i, a)))
  }

export const searchSeries =
  (
    query: string,
    database: string,
    lastSeriesId?: string,
    lastScore?: number,
    lastDbName?: string
  ) =>
  async () => {
    ga.track('search', 'search query', { query })
    return API.searchSeries(query, database, lastSeriesId, lastScore, lastDbName)
  }
