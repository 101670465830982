import * as Sentry from '@sentry/react'
import keycode from 'keycode'
import React, { useEffect } from 'react'
import enhanceWithClickOutside from 'react-click-outside'

import * as bem from '../bem'
import { MAX_VARIABLES } from '../constants'
import { SEARCH_PANEL } from '../messages'
import { Button } from './Button'
import { Input } from './Input'
import { withResponsive } from './Responsive'
import { ReactComponent as ClearInput } from 'static/clear-input.svg'

import './Autocomplete.scss'
import Loader from './Loader'
import { IFoundSeries } from 'global'

interface IInputProps {
  value: string
  onInput?: React.FormEventHandler<HTMLInputElement>
  onInputClear: () => void
}

export interface IProps {
  items: IFoundSeries[]
  inputProps: IInputProps
  focus?: boolean
  onSuggestionsFetchRequested: () => void
  onSuggestionsFetchCleared: () => void
  onSearch: () => void
  onSelect: (dbId: string, id: string, append?: boolean) => void
  variablesCount: number
  isMobile: boolean
  pending: boolean
}

interface IState {
  isOpen: boolean
  selected: number
}

export class SeriesAutocomplete extends React.Component<IProps, IState> {
  state: IState = {
    isOpen: false,
    selected: -1,
  }
  private menuRef = React.createRef<HTMLUListElement>()

  handleClickOutside = () => this.state.isOpen && this.close()

  render() {
    const { inputProps, items, variablesCount, pending } = this.props
    const { selected, isOpen } = this.state
    const { block, element } = bem.bindBem('SeriesAutocomplete')

    return (
      <div className={block({ isOpen: isOpen && items.length > 0 })}>
        <this.Input {...inputProps} pending={pending} />
        <ul className={element('Menu')} ref={this.menuRef}>
          {items &&
            items.map((s, i) => (
              <React.Fragment key={`${s.databaseId}-${s.id}-${i}`}>
                {i > 0 ? <div className={element('Separator')} /> : null}
                <this.MenuItem
                  series={s}
                  isSelected={i === selected}
                  variablesCount={variablesCount}
                />
              </React.Fragment>
            ))}
        </ul>
      </div>
    )
  }

  MenuItem = (props: {
    isSelected?: boolean
    series: IFoundSeries
    variablesCount: number
  }) => {
    const ref = React.useRef<HTMLLIElement>()

    useEffect(() => {
      if (props.isSelected) {
        const self = ref.current
        const menu = this.menuRef.current
        if (self.offsetTop < menu.scrollTop) {
          menu.scrollTop = self.offsetTop
        } else if (self.offsetTop > menu.scrollTop + menu.offsetHeight) {
          // Item is off the top of the visible area
          menu.scrollTop = self.offsetTop + self.offsetHeight - menu.offsetHeight + 4
        }
      }
    })

    const { block, element } = bem.bindBem('MenuItem')
    const inputValue = this.props.inputProps.value.toUpperCase()

    const annotateId = (id: string) =>
      id.indexOf(inputValue) === 0 ? (
        <span>
          <b>{inputValue}</b>
          {id.slice(inputValue.length)}
        </span>
      ) : (
        id
      )

    return (
      <li
        className={block({ isSelected: props.isSelected })}
        ref={props.isSelected ? ref : undefined}
        onClick={() => this.onSelect(props.series.databaseId, props.series.id)}
      >
        <div className={element('Description')}>{props.series.description}</div>
        <div className={element('ID')}>
          {annotateId(props.series.id)}
          <span className={element('DB')}>{props.series.databaseId}</span>
        </div>
        {!this.props.isMobile &&
          props.variablesCount < MAX_VARIABLES &&
          props.variablesCount > 0 && (
            <Button
              className={element('AddSeries')}
              text="Add"
              style="dark"
              onClick={event => {
                event.stopPropagation()
                this.onSelect(props.series.databaseId, props.series.id, true)
              }}
              size="small"
            />
          )}
      </li>
    )
  }

  Input = ({ onInputClear, pending, ...props }: IInputProps & { pending: boolean }) => {
    const postfix = pending ? (
      <Loader />
    ) : props.value.length > 0 ? (
      <Button icon={<ClearInput />} size="small" style="text" onClick={onInputClear} />
    ) : null

    return (
      <Input
        name="search"
        placeholder={SEARCH_PANEL.PLACEHOLDER}
        {...props}
        onFocus={this.open}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          this.open()
          this.onChange(e.currentTarget.value)
          props.onInput(e)
        }}
        onKeyDown={this.onKeyDown}
        postfix={postfix}
        focus={this.props.focus}
      />
    )
  }

  private open = () => this.setState({ isOpen: true })
  private close = () => this.setState({ isOpen: false, selected: -1 })
  private onChange = (value: string) => {
    this.setState({ selected: -1 })
    if (value === '') {
      this.props.onSuggestionsFetchCleared()
    } else {
      this.props.onSuggestionsFetchRequested()
    }
  }

  private onKeyDown: React.KeyboardEventHandler = e => {
    const { selected } = this.state
    const { items } = this.props

    if (keycode(e.which) === 'enter') {
      e.preventDefault()

      if (selected < 0) {
        this.close()
        this.props.onSearch()
      } else {
        this.onSelect(items[selected].databaseId, items[selected].id)
      }
    }
    if (keycode(e.which) === 'down' && selected < items.length - 1) {
      this.setState(() => ({ selected: selected + 1 }))
      e.preventDefault()
    } else if (keycode(e.which) === 'up' && selected > 0) {
      this.setState(() => ({ selected: selected - 1 }))
      e.preventDefault()
    }
  }
  private onSelect = (dbId: string, seriesId: string, append = false) => {
    this.close()
    this.props.onSelect(dbId, seriesId, append)
  }
}

export default Sentry.withProfiler(
  withResponsive<IProps>(enhanceWithClickOutside(SeriesAutocomplete))
)
