import React from 'react'
import { IFormProps, InnerForm, Variants } from './AdvancedFunctionUtils'
import { Formik } from 'formik'
import { Input } from '../Input'
import { bindBem } from '../../bem'
import { AutoSave } from './AutoSave'
import { ADVANCED_FUNCTIONS, FUNCTIONS_DIALOG } from '../../messages'
import { Freq, isWeekly } from '../../services/series'
import RadioButton from '../RadioButton'
import { RadioGroup } from '../RadioGroup'
import { Switch } from './Switch'
import * as Yup from 'yup'

enum FieldName {
  Value = 'value',
  Func = 'func',
  Centered = 'centered',
}

export const validationSchema = Yup.object().shape({
  [FieldName.Value]: Yup.number().min(1).max(200).required(),
  [FieldName.Func]: Yup.string().required(),
  [FieldName.Centered]: Yup.boolean(),
})

export const FnChangeForm = (props: IFormProps) => {
  const { state, setForm, setFormValidation, isNested } = props
  const { centered, baseFunc, func } = state
  const value = state.args[0] as number
  const { block, element } = bindBem('FnChangeForm')
  const initialValues = {
    [FieldName.Value]: value !== null ? value : '',
    [FieldName.Func]: func,
    [FieldName.Centered]: centered,
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values =>
        setForm({
          ...state,
          args: [values[FieldName.Value]],
          func: values[FieldName.Func],
          centered: values[FieldName.Centered],
        })
      }
      validationSchema={validationSchema}
      isInitialValid={true}
    >
      {({ errors, values, submitForm, isValid, handleChange, setFieldValue }) => (
        <InnerForm {...props} className={block()} onSave={submitForm} isValid={isValid}>
          <div className="row">
            <Input
              value={values[FieldName.Value]}
              onChange={handleChange}
              type="number"
              name={FieldName.Value}
              error={errors.value && !!errors.value}
            />
            <div className={element('RightLabel')}>
              {isWeekly(props.frequency)
                ? ADVANCED_FUNCTIONS.PERIODS[Freq.Mon]
                : ADVANCED_FUNCTIONS.PERIODS[props.frequency]}
            </div>
          </div>
          {errors[FieldName.Value] && (
            <div className="row">
              <div className={element('Error')}>{errors[FieldName.Value]}</div>
            </div>
          )}

          <RadioGroup
            value={values[FieldName.Func]}
            onChange={(func: AdvancedFunction) => setFieldValue(FieldName.Func, func)}
          >
            {Variants[baseFunc].map(o => (
              <RadioButton key={o} value={o}>
                {ADVANCED_FUNCTIONS.OPTIONS[o]}
              </RadioButton>
            ))}
          </RadioGroup>
          <Switch
            onChange={flag => setFieldValue(FieldName.Centered, flag)}
            value={values[FieldName.Centered]}
            title=""
          >
            {FUNCTIONS_DIALOG.CENTERED}
          </Switch>

          {!isNested && (
            <AutoSave
              onSubmit={submitForm}
              values={values}
              isValid={isValid}
              callback={() => setFormValidation(isValid)}
            />
          )}
        </InnerForm>
      )}
    </Formik>
  )
}
