// eslint-disable max-len
// eslint-disable max-lines

import { Freq } from './services/series'

export const LOGIN_ERROR = 'Invalid email/password combination. Please try again.'
export const RESTRICTED_LOGIN_ERROR = 'Login from an unauthorized network address denied.'
export const USER_INACTIVE = 'Your account is inactive. Please contact client support.'
export const ORG_INACTIVE =
  'Your organization is inactive. Please contact client support.'
export const LOGIN = 'Login'
export const CONTINUE = 'Continue'

export const RESEND_PASSWORD_RESET_REQUEST_SUCCESS =
  'Link to reset your password has been sent to your mailbox.'
export const RESEND_PASSWORD_RESET_REQUEST_ERROR =
  'Link to reset your password cannot be sent to your mailbox. Please try again later or contact support.'

export const USER_FORM_TOASTS = {
  RESEND_SUCCESS: 'The invitation email was sent.',
  RESEND_FAILED: 'Could not resend invitation.',
}

const currentYear = new Date().getFullYear()
export const CLIENT_SUPPORT = {
  HEADER: 'Client Support',
  DESCRIPTION:
    'Please call our client support line staffed by database managers with extensive knowledge of economic data, or email our support staff with any inquires.',
  AVAILABLE1: 'Available 24hr',
  AVAILABLE2: '8AM Sun - 7PM Fri ET',
  EMAIL_ADDRESS: 'Email address:',
  FOOTER: 'Email address:',
  COPYRIGHT: `Copyright ${currentYear} by Haver Analytics. All rights reserved.`,
  FEEDBACK: 'Feedback',
  CONTACT_US: 'Contact Us',
  DISCLAIMER: 'Disclaimer',
  PRIVACY: 'Privacy',
  PRIVACY_POLICY: 'Privacy Policy',
  LEGAL: 'Legal',
  HELP: 'Help',
  ABOUT: 'About',
  DATA_NEWS: 'Data News',
  RELEASE_CALENDARS: 'Release Calendars',
}

export const SEARCH_PANEL = {
  TITLE: 'Search',
  PLACEHOLDER: 'Find series by name or keyword...',
  RESULTS: 'Results for',
  INFO: 'Start searching',
  FINDING: 'Finding results',
  SUBINFO: 'To display results',
  DATABASES: 'databases',
  SERIES: 'series',
  NO_RESULTS_INFO: 'No results for',
  NO_RESULTS_SUBINFO: 'Try different query',
  BACK: 'Back to directory',
}

export const SHORTCUTS_PANEL = {
  TITLE: 'Keyboard shortcuts',
  SHORTCUTS: {
    DISPLAY_DATABASE_LIST: 'Display the Database List',
    PRINT: 'Print',
    ZOOM: 'Open Zoom Panel',
    SET_TITLES: 'Set Titles',
    TOGGLE_LEGENDS: 'Show/Hide Legends',
    SCROLL_LEFT: 'Scroll left',
    SCROLL_RIGHT: 'Scroll right',
    SCROLL_LEFT_BY_PERIOD: 'Scroll left by period',
    SCROLL_RIGHT_BY_PERIOD: 'Scroll right by period',
    INCREMENT_OFFSET: 'Lead series',
    DECREMENT_OFFSET: 'Lag series',
    TOGGLE_LOG_SCALE: 'Toggle Log Scale',
  },
}

export const DATA_DIRECTORY_BANNER = {
  MAIN_TITLE: 'Are you interested in this database?',
  EMAIL_US: 'Email us:',
}

export const SELECT_SERIES_PANEL = {
  TITLE: 'Add a series',
  SUBTITLE: 'Up to 8 series can be added',
}

export const SIDEBAR = {
  DATA_DIRECTORY: 'Data Directory',
  DATA_UPDATES: 'Data Updates',
  DIRECTORY: 'Directory',
  GRAPH: 'Graph',
  TOOLS: 'Tools',
  FUNCTIONS: 'Functions',
  EXPORT: 'Export',
  SERIES: 'Series',
  ADD_SERIES: 'Add series',
  UNFREEZE_SERIES: 'Unfreeze',
  START_NEW: 'Start new graph',
  COPY: 'Copy',
  COPIED: 'Copied',
  DATA_DIRECTORY_ALL: 'ALL',
  DATA_DIRECTORY_STARRED: 'FAVORITES',
  DEFAULT_MENU_DESCRIPTION: 'Variants available',

  DATA_UPDATES_TAB: {
    TITLE: 'Data Updates are\ncoming soon to HaverView.',
    LINK: 'See Data Updates in Haver.com',
  },
  SERIES_TAB: {
    INFORMATION: 'Information',
    PARAMETERS: 'Parameters',
    UNDO: 'Undo',
    VARIABLE_REMOVED: 'Series removed!',
    MAX_VARIABLES_ERROR: 'You have added the maximum number of series.',
    DATA_MARKERS: 'Data markers',
    DATA_LABELS: 'Data labels',
    TREND_LINE: 'Trend Line',
    REPLACE_SERIES: 'Replace Series',
  },
  EXPORT_TAB: {
    TITLE: 'Export & Sharing',
    PRINT: 'Print',
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE',
    GRAPH_N_DATA: 'Graph & Data',
    GRAPH_ONLY: 'Graph only version',
    DOWNLOAD_DATA: 'DOWNLOAD DATA',
    DOWNLOAD: 'Download...',
    EXPORT_GRAPH: 'EXPORT GRAPH',
    COPY_DATA_LINK: 'Copy Data Link',
    SHARE_LINK: {
      TITLE: 'Share link',
      UPDATE: 'Update',
      GENERATE: 'Generate link',
      GENERATE_NEW: 'Generate new link',
      PNG_LABEL: 'Png',
      SVG_LABEL: 'Svg',
    },
    EXPORT_API_DATA: {
      TITLE: 'Export API data',
      URL: 'URL',
      PAYLOAD: 'Payload',
    },
  },
  GRAPH_OPTIONS: {
    TITLE: 'Graph options',
    GENERAL: 'General',
    TICKS: 'Ticks',
    X_AXIS_TICKS: 'X Axis Ticks',
    ALL_TICKS_MARKS: 'All ticks marks',
    MAJOR_TICKS_MARKS: 'Major ticks marks',
    GRIDS: 'Grids',
    GRAPH_TYPES: 'Graph Types',
    LINE: 'Line Graph',
    SPLINE_LINE: 'Spline line',
    LINE_SHADOW: 'Line Shadow',
    BAR: 'Bar Graph',
    LEGEND: 'Legend',
    LEGEND_ON: 'Show',
    LEGEND_OFF: 'Hide',
    DATA_MARKERS: 'Data markers',
    DATA_LABELS: 'Data labels',
    ALL_DATA_LABELS: 'All',
    LAST_DATA_LABELS: 'Last',
    RECESSION: 'Recession',
    Y_AXIS_TITLES: 'Y-axis titles',
    Y_AXIS_LOG_SCALE: 'Y-axis Log Scale',
    HORIZONTAL_GRID: 'Horizontal grid',
    VERTICAL_GRID: 'Vertical grid',
    SHADING: 'Shading',
    LINES: 'Lines',
    COUNTRY: 'Country',
    SET_TITLES: 'Set / Update Legend...',
    RESET_ALL: 'Reset all',
    COUNTRY_DIALOG: {
      TITLE: 'Recession country',
      INPUT_PLACEHOLDER: 'Type to filter',
    },
    ZOOM: 'Zoom',
    ZOOM_DIALOG: {
      TITLE: 'Zoom',
      AXIS: 'axis',
      NOTE: 'Note: Leave blank and click "Apply" to see the total available time span.',
      NOTE_OPTIONS:
        'Use: d - days, w - week, m - month, q - quarter for more precise zoom. Example: type 3m for 3 month zoom',
      INFO: 'of Years',
    },
  },
  AGGREGATION: {
    TITLE: 'Aggregation',
  },
  SCALING_OPTIONS: {
    TITLE: 'Scaling options',
  },
  TRANSFORMATION: {
    TITLE: 'Perform Transformation',
  },
  PARAMETERS: {
    TITLE: 'Series Parameters',
    COPY: 'Copy to clipboard',
    COPIED: 'Copied!',
    FREQUENCY: 'Frequency',
    START_DATE: 'Start date',
    END_DATE: 'End Date',
    NO_OF_PERIODS: '# of Periods',
    MAX_NO_OF_PERIODS: 'MAX # of Periods',
    MAGNITUDE: 'Magnitude',
    DECIMAL_PRECISION: 'Decimal precision',
    DIFFERENCE_TYPE: 'Difference type',
    AGGREGATION_TYPE: 'Aggregation type',
    DATA_TYPE: 'Data type',
    UPDATE_GROUP: 'Update group',
    GEOGRAPHY: 'Geography',
    LAST_MODIFIED: 'Last modified',
    SHORT_SOURCE: 'Short source',
    LONG_SOURCE: 'Long source',
  },
  UNDEFINED: 'Undefined',
  TOOLTIPS: {
    DRAG_AND_DROP: {
      HEADER: 'Now you can use Drag & Drop',
      REARRANGE: 'Rearrange order of the series',
      TRANSFORMATION: 'Perform Transformation',
      BUTTON: 'I got it',
    },
  },
  DATA_DIRECTORY_TAB: {
    OTHER_DATABASES_SHORT: 'Others',
    OTHER_DATABASES: 'Other Databases',
  },
}

export const AGGREGATION_LABEL: { [index: number]: string } = {
  [Freq.Mon]: 'Weekly',
  [Freq.Monthly]: 'Monthly',
  [Freq.Quarterly]: 'Quarterly',
  [Freq.Annually]: 'Annual',
}
export const AGGREGATION_TYPES: { [index: number]: string } = {
  1: 'Average',
  2: 'Sum',
  3: 'End of Period',
  0: 'Undefined',
  9: 'Not Allowed',
}

export const SETTINGS_DROPDOWN = {
  ADMIN_SETTINGS: 'Admin Settings',
  PROFILE: 'Profile',
  CHANGE_PASSWORD: 'Change Password',
  API_KEY: 'Your API Key',
  LOGOUT: 'Logout',
}

export const GRAPH = {
  [Freq.Decennial]: 'Decennial',
  [Freq.Quinquennial]: 'Quinquennial',
  [Freq.BiYearly]: 'Bi-Yearly',
  [Freq.Annually]: 'Annually',
  [Freq.Quarterly]: 'Quarterly',
  [Freq.Monthly]: 'Monthly',
  [Freq.Mon]: 'Weekly',
  [Freq.Tue]: 'Weekly',
  [Freq.Wen]: 'Weekly',
  [Freq.Thu]: 'Weekly',
  [Freq.Fri]: 'Weekly',
  [Freq.Sat]: 'Weekly',
  [Freq.Sun]: 'Weekly',
  [Freq.Daily]: 'Daily',
  SOURCE: 'Source',
  SCATTER_RANGE: (start: string, end: string) => `${start} - ${end}`,
}

export const EDIT_TITLES_DIALOG = {
  TITLE: 'Title & Legend',
  SERIES_NAME_PLACEHOLDER: 'TYPE SERIES NAME HERE...',
  SERIES_TITLE_TOO_LONG: 'Series name must be shorter than 80 characters.',
  RESET_ALL: 'Reset All',
  RESET: 'Reset',
  APPLY: 'Apply',
}

export const ZOOM_DIALOG = {
  TITLE: 'Zoom',
  RESET: 'Reset',
  APPLY: 'Apply',
  ZOOM_FORMAT: 'Zoom should be a number with an optional unit',
  ZOOM_TO_LARGE: 'Zoom cannot be larger than 100 years',
  ZOOM_WRONG_UNIT: 'Accepted units are: y, q, m, w, d.',
}

export const FREQUENCY_LABEL: { [index: number]: string } = {
  [Freq.Annually]: 'Year',
  [Freq.Quarterly]: 'Quarter',
  [Freq.Monthly]: 'Month',
  [Freq.Mon]: 'Week',
  [Freq.Tue]: 'Week',
  [Freq.Wen]: 'Week',
  [Freq.Thu]: 'Week',
  [Freq.Fri]: 'Week',
  [Freq.Sat]: 'Week',
  [Freq.Sun]: 'Week',
  [Freq.Daily]: 'Day',
}

export const FUNCTIONS: { [key in BasicFunction]: string } = {
  DIFA: 'Diff Annual Rate',
  'DIFA%': '% Annual Rate',
  'DIFF%': '% Period',
  'YRYR%': '% Year/Year',
  YRYR: 'Diff Year/Year',
  DIFF: 'Diff Period',
  DIFAL: 'Diff Log Annual Rate',
  DIFFL: 'Diff log Period',
  YRYRL: 'Diff Log Year/Year',
  LEVEL: 'Level (default)',
}

const PERIODS: { [key: number]: string } = {
  [Freq.Annually]: 'Years',
  [Freq.Quarterly]: 'Quarters',
  [Freq.Monthly]: 'Months',
  [Freq.Daily]: 'Days',
  [Freq.Mon]: 'Weeks',
}

export const ADVANCED_FUNCTIONS = {
  diff: 'Change',
  'diff%': '% Change',
  diffl: 'Log Change',
  movv: 'Level',
  index: 'Index',
  value: 'Value',
  ytd: 'Year To Date',
  dytd: 'Reverse YTD',
  zs: 'Z-Score',
  na2z: 'N/A to Zero',
  z2na: 'Zero to N/A',
  sa: 'Seasonal Adjustment',
  OPTIONS: {
    diff: 'Point-to-point',
    difa: 'Annualized',
    difv: 'Average',
    'diff%': 'Point-to-point',
    'difa%': 'Annualized',
    'difv%': 'Average',
    diffl: 'Point-to-point',
    difal: 'Annualized',
    difvl: 'Average',
    movv: 'Average',
    movt: 'Total',
    mova: 'Annualized',
  },
  PERIODS,
}

export const FUNCTIONS_DIALOG = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  RESET: 'Reset',
  APPLY: 'Apply',
  ALLOW_NESTING: 'ALLOW NESTING',
  SEASONAL_ADJUSTMENT: 'Seasonal adjustment',
  ADD: 'Add',
  CANCEL: 'Cancel',
  CENTERED: 'Centered',
  CURRENCY_CONVERSION: 'Currency conversion',
  CURRENCY_DIALOG_TITLE: 'Currency Conversion Options',
  CURRENCY: 'Currency',
  CANT_SET_SEASONAL_ADJUSTMENT:
    'Seasonal adjustment is available only to series with Monthly or Quarterly frequency.',
  FORMAT_VALIDATION_MESSAGE: (field: string, formats: string[]) =>
    `${field} must match formats: ${formats.join(', ')}`,
  VALUE_VALIDATION_MESSAGE: (format: string, min: number, max: number) =>
    `${format} must be between ${min} and ${max}`,
  AT_LEAST_3_YEARS_REQUIRED: 'Start date and end date must be at least 3 years apart',
  DATE_AFTER_2099: 'Year cannot be greater than 2099',
  SA_BEGIN_DATE_LABEL: 'Begin date',
  SA_BEGIN_DATE_REQUIRED: 'Begin date is required',
  SA_END_DATE_LABEL: 'End date',
  SA_NOTE: 'Note: Leave blank to see the total available time span.',
}

export const SCATTER_MENU = {
  SCATTERED_GRAPH: 'Scatter graph',
  CORRELATION: 'Correlation',
  DESCRIPTION: 'To use different series drag & drop it between the 2 top series',
  NOT_ENOUGH_VARS: 'You need at least two series.',
  VAR_MISMATCH: 'These series have different frequency.',
  SCATTER_VAR_MISMATCH_HINT: 'To use Scatter Graph agregate',
  CORRELATION_VAR_MISMATCH_HINT: 'To use Correlation agregate',
  CANT_BE_AGGREATED: "can't be aggregated.",
  VAR_MISMATCH_TO: 'to',
  AGGREGATE: 'AGGREGATE',
  CANCEL: 'CANCEL',
  REGRESSION_LINE: 'Regression Line',
  REGRESSION_LINEAR: 'Linear',
  REGRESSION_EXP: 'Exponential',
  REGRESSION_LOG: 'Logarithmic',
  TIME_LINE: 'Time Line',
  TYPE_OF_AXIS: 'Type of scale',
  LEFT_AXIS: 'Left axis',
  LEFT_AND_RIGHT_AXIS: 'Left and right axis',
}

export const SERIESBOX_TRANSFORMATION = {
  UNIT_MISMATCH: (type1: string, type2: string) =>
    `Adding a series of type [${type1}] to a series of type [${type2}] is not recommended.`,
  FREQ_MISMATCH: (frequency: string) =>
    `Series have different frequencies. Result of this operation will be aggregated to ${frequency}.`,
}

export const TICKS_SCALE_FORM = {
  NUMBER_OF_TICKS: 'Number of ticks',
  AUTOMATIC: 'AUTOMATIC',
  INVERT_SCALE: 'Invert scale',
  MINMAX: 'MIN / MAX',
  INCREMENTAL: 'INCREMENTAL',
  VALUES: 'VALUES',
  VALUES_PLACEHOLDER: 'E.g. 1, 2, 3',
  MIN_SHOULD_BE_A_NUMBER: 'Min should be a number',
  MAX_SHOULD_BE_A_NUMBER: 'Max should be a number',
  SHOULD_BE_A_NUMBER: 'You must specify a number',
  IS_REQUIRED: 'This field is required',
  IS_NUMBER_ARRAY: 'Specify a sequence of numbers in format v1, v2, v3...',
  MORE_THAN_ONE_TICK: 'Specify more than one tick',
}

export const SCALING_OPTIONS_DIALOG = {
  RESET_ALL: 'Reset All',
  APPLY: 'Apply',
  TYPE_OF_SCALE: 'Type of scale',
  NEITHER: 'Neither (Default)',
  UNISCALE: 'Uniscale',
  GLOBAL: 'Global',
  BOTH: 'Both',
  SERIES_ASSIGNMENT: 'Series Assignment',
  MORE_OPTIONS: 'More options',
  LESS_OPTIONS: 'Less options',
  LEFT_SCALE: 'Left scale',
  RIGHT_SCALE: 'Right scale',
}

export const TOOLTIPS = {
  SHARE: 'Share',
  GRAPH_SETTINGS: 'Graph Settings',
  GRAPH_TYPE: 'Graph type',
  FUNCTION: 'Function',
  INTERPOLATION: 'Interpolation',
  CORRELATION: 'Correlation',
  LOG_SCALE: 'Log scale',
  ZOOM: 'Zoom',
  KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
  SUPPORT: 'Support',
  UNDO: 'Undo',
  RESET: 'Reset',
  TOOLBAR: {
    GRPAH_TYPE: 'Graph type – globally',
    FUNCTION: 'Function – globally',
    INTERPOLATION: 'Interpolation – globally',
  },
}

export const DEFAULT_GRAPH_TITLE = 'Add Graph Title'
export const DEFAULT_EXPORT_FILENAME = 'Haver - Export'

export const AGGREGATION_FORBIDDEN_TOAST = {
  title: 'Aggregation was not applied to all series',
  description: 'Performing aggregation is not allowed to ',
}

export const API_ERRORS = {
  USERS: {
    GENERAL: {
      TITLE: 'Can not perform given operation.',
      SUBTITLE: (msg?: string) => msg || 'Please try again',
    },
  },
  DATABASES: {
    GENERAL: {
      TITLE: 'Database is unavailable.',
    },
  },
  SERIES: {
    NOT_FOUND: {
      TITLE: 'Selected series is currently not available.',
      SUBTITLE: '',
    },
    GENERAL: {
      TITLE: 'Please try again with another one.',
      SUBTITLE: 'Server error. Try again or contact helpdesk',
    },
    CORRELATION_INCORRECT_RANGE: {
      TITLE: 'Could not load correlation.',
      SUBTITLE: 'Not enough observations',
    },
    CORRELATION_BEFORE_MIN: {
      TITLE: 'Could not load correlation.',
      SUBTITLE: 'Time range must start after 1921-01-01',
    },
    PERMISSIONS: {
      TITLE: 'Please try again with another one.',
      SUBTITLE: "You don't have permission to display it",
    },
    SA_INCORRECT_RANGE: {
      TITLE: 'Invalid date range for seasonal adjustment.',
      SUBTITLE: 'Time range must include at least 3 years',
    },
    TRDLN_BEFORE_MIN: {
      TITLE: 'Invalid date range for trendline.',
      SUBTITLE: 'Time range must start after 1921-01-01',
    },
  },
}

export const MAINTENANCE = {
  TITLE: 'Maintenance mode',
  CONTENT:
    'HaverView is temporarily unavailable while we perform system maintenance and upgrades. The site will be operational shortly. Don’t refresh your page if you don’t want to lose your data/progress. Thank you for your patience.',
}

export const USER_FORM = {
  VALIDATIONS: {
    REQUIRED: 'This field is required.',
    INVALID_IP: 'The IP address is invalid.',
    INVALID_EMAIL: 'The email address is invalid.',
    INVALID_RATELIMIT: 'The rate limit format is incorrect.',
  },
}

export const DUPLICATED_VALUE = (key: string, value: string) =>
  `Duplicated ${key} for ${value}`

export const PLEASE_WAIT = 'Please wait'
