// eslint-disable max-lines

import * as React from 'react'
import { throttle } from 'lodash'
import { bindBem } from '../../bem'

import { FxButton, InterpolationButton, RevertButton } from './Buttons'
import { DropdownButton } from './DropdownButton'
import { SwitchButton } from '../SwitchButton'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import * as Drop from '../Droppable'
import { GraphTypeDropdown } from './GraphTypeDropdown'
import * as transformations from '../../store/Transformations'
import { SIDEBAR } from '../../messages'
import { ButtonSeparator } from '../Sidebar/Buttons'
import { getSeriesLabelJSX } from './SeriesBoxTransformationLabel'
import { AsideDialog } from '../Modal'
import { ParametersDialog } from './ParametersDialog'
import {
  dataMarkersAvailability,
  hasTrendLine,
  dataLabelsAvailability,
} from '../../services/series'

import { ReactComponent as Hamburger } from 'static/hamburger.svg'

import './SeriesBox.scss'
import { Button } from '../Button'

export interface IProps {
  series: IDataSeries
  color: string
  onDelete: () => void
  isDraggable?: boolean
  isDroppable?: boolean
  isTrendLineSource: boolean
  isTrendlineDisabled: boolean
  graphType: ISeriesType
  onDragStarted: () => void
  onDragStopped: () => void
  onChartTypeChange: (type: ISeriesType) => void
  toggleInterpolation: React.MouseEventHandler<HTMLButtonElement>
  toggleTrendLine: (enabled: boolean) => void
  openFunctionsModal: () => void
  onDrop: (event: React.MouseEvent<HTMLElement>) => void
  isTransformationReversable: boolean
  revertTransformation: () => void
  stackedBarDisabled: boolean
  toggleDataMarkers: () => void
  toggleDataLabels: () => void
  onDoubleClick: () => void
  unfreezeSeries: () => void
}

export interface IState {
  isDragging: boolean
  position: { x: number; y: number }
  placeholder?: { height: number; offset: number }
  displayParameters?: boolean
  revertDisabled: boolean
  clicked: boolean
}

export class SeriesBox extends React.Component<IProps, IState> {
  _isMounted = false

  state: IState = {
    position: { x: 0, y: 0 },
    isDragging: false,
    placeholder: null,
    displayParameters: false,
    revertDisabled: false,
    clicked: false,
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  private ThisRef = React.createRef<HTMLDivElement>()

  private onDrag = throttle<DraggableEventHandler>((_e, data) => {
    this.setState(() => ({ position: { x: 0, y: data.y } }))
  }, 12)

  private onRevert = () => {
    this.setState({ revertDisabled: true })
    this.props.revertTransformation()
  }

  render() {
    const { series, color, onDelete, graphType, onChartTypeChange } = this.props
    const { isDraggable, isDroppable, isTrendLineSource, isTrendlineDisabled } =
      this.props
    const { toggleInterpolation, toggleTrendLine, stackedBarDisabled, unfreezeSeries } =
      this.props
    const { onDrop, isTransformationReversable, toggleDataMarkers, toggleDataLabels } =
      this.props
    const { isDragging, position, displayParameters, revertDisabled } = this.state
    const { element, block } = bindBem('SeriesBox')
    const isTrendLine = hasTrendLine(series)
    const dataMarkersDisabled = !dataMarkersAvailability[graphType]
    const dataLabelsDisabled = !dataLabelsAvailability[graphType]

    return (
      <>
        <Drop.Droppable onDrop={onDrop} isDroppable={isDroppable}>
          <div>
            <Draggable
              position={position}
              cancel=".no-cursor"
              axis="y"
              defaultClassNameDragging={block({ dragging: true })}
              onStart={this.onStart}
              onDrag={this.onDrag}
              onStop={this.onStop}
              disabled={!isDraggable}
            >
              <div
                className={`${block({
                  isDraggable,
                  isDragging,
                  isDroppable,
                })} series-${series.id}`}
                ref={this.ThisRef}
                onDoubleClick={this.onDoubleClick}
                data-series-uuid={series.uuid}
              >
                <div
                  className={`${element(
                    'transformationDropzone'
                  )} row middle-xs center-xs`}
                >
                  {SIDEBAR.TRANSFORMATION.TITLE}
                </div>
                <div
                  className={`${element('Stripe')} box`}
                  style={{ backgroundColor: color }}
                />
                <div className={element('ContentWrapper')}>
                  <div className={element('Title')}>
                    {series.transformation
                      ? getSeriesLabelJSX(series, 'light')
                      : series.description}
                  </div>
                  <div className={`${element('ButtonContainer')} row between-xs`}>
                    <div className={`${element('LeftButtons')} row middle-xs no-cursor`}>
                      <GraphTypeDropdown
                        value={graphType}
                        onChange={onChartTypeChange}
                        onlyLines={isTrendLine}
                        stackedBarDisabled={stackedBarDisabled}
                        scatterDisabled
                      />
                      {!isTrendLine && (
                        <>
                          <FxButton
                            onClick={this.props.openFunctionsModal}
                            active={transformations.isFunction(series.transformation)}
                          />
                          <InterpolationButton
                            active={transformations.hasInterpolation(series)}
                            onClick={toggleInterpolation}
                          />
                        </>
                      )}
                      {series.isFrozen && (
                        <Button
                          onClick={() => unfreezeSeries()}
                          className={element('AddSeriesBtn')}
                          style="dark"
                          text={SIDEBAR.UNFREEZE_SERIES}
                        />
                      )}
                    </div>
                    <div className={`${element('RightButtons')} row start-xs no-cursor`}>
                      {isTransformationReversable && (
                        <>
                          <ButtonSeparator />
                          <RevertButton
                            onClick={this.onRevert}
                            disabled={revertDisabled}
                          />
                          <ButtonSeparator />
                        </>
                      )}
                      <DropdownButton>
                        <ul>
                          {!isTrendLine && (
                            <>
                              <li>
                                <SwitchButton
                                  text={SIDEBAR.SERIES_TAB.TREND_LINE}
                                  isOn={isTrendLineSource}
                                  onClick={toggleTrendLine}
                                  disabled={isTrendlineDisabled}
                                />
                              </li>
                              <li className="separator" />
                            </>
                          )}
                          <li>
                            <SwitchButton
                              text={SIDEBAR.SERIES_TAB.DATA_MARKERS}
                              isOn={!dataMarkersDisabled && series.dataMarkers}
                              onClick={toggleDataMarkers}
                              disabled={dataMarkersDisabled}
                            />
                          </li>
                          <li>
                            <SwitchButton
                              text={SIDEBAR.SERIES_TAB.DATA_LABELS}
                              isOn={!dataLabelsDisabled && series.hasDataLabels}
                              onClick={toggleDataLabels}
                              disabled={dataLabelsDisabled}
                            />
                          </li>
                          <li className="separator" />
                          <li onClick={this.openParameters}>Series parameters</li>
                          <li className="separator" />
                          <li
                            className={`${!onDelete && 'disabled'} error`}
                            onClick={onDelete}
                          >
                            Remove
                          </li>
                        </ul>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <this.Handler />
              </div>
            </Draggable>
          </div>
        </Drop.Droppable>
        {isDragging ? <this.Placeholder /> : null}
        <AsideDialog
          isOpen={displayParameters}
          onClose={this.closeParameters}
          title={SIDEBAR.PARAMETERS.TITLE}
        >
          <ParametersDialog series={series} />
        </AsideDialog>
      </>
    )
  }

  private Placeholder = () => {
    const { height, offset } = this.state.placeholder
    return (
      <div
        style={{ height, marginTop: offset }}
        className={bindBem('SeriesBox').element('Placeholder')}
      />
    )
  }

  private Handler = () => {
    return (
      <div className={`${bindBem('SeriesBox').element('Handler')}`}>
        <Hamburger />
      </div>
    )
  }

  private closeParameters = () => this.setState({ displayParameters: false })
  private openParameters = () => this.setState({ displayParameters: true })
  private registerClick = () => {
    this.setState(() => ({ clicked: true }))
    setTimeout(this.removeClick, 300)
  }

  private removeClick = () => {
    if (this._isMounted) {
      this.setState(() => ({ clicked: false }))
    }
  }

  private onDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e?.target as Element)?.matches('button, button *')) {
      this.props.onDoubleClick()
    }
  }

  private onStart: DraggableEventHandler = (_e, data) => {
    if (this.state.clicked) {
      return false
    }
    this.registerClick()
    const placeholder = {
      height: this.ThisRef.current.clientHeight,
      offset: this.ThisRef.current.offsetTop,
    }
    this.setState(() => ({
      position: { x: data.x, y: data.y },
      isDragging: true,
      placeholder,
    }))
    this.props.onDragStarted()
  }
  private onStop: DraggableEventHandler = () => {
    this.onDrag.cancel()
    this.setState(() => ({
      position: { x: 0, y: 0 },
      isDragging: false,
    }))
    setTimeout(this.props.onDragStopped, 10) // fight timing issue by scheduling onDragStop for later
  }
}
