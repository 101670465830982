import Axios from 'axios'
import pako from 'pako'

import { IRootState } from '.'

import { dumpSeriesState } from '../services/series'
import {
  getChartNode,
  getPngBlob,
  getSvgBlob,
  makeNodePrintable,
} from '../services/export'
import { ACTIONS, AsyncAction } from './Series'
import { openToast } from './User'
import {
  createLinkShareData,
  getSharedLinkData,
  updateLinkShareData,
  uploadShareFile,
} from '../api/series'

const uploadSVG = (uploadUrl: string) => {
  const blob = getSvgBlob(makeNodePrintable(getChartNode()))
  uploadShareFile(blob, uploadUrl, 'image/svg+xml')
}

const uploadPNG = (uploadUrl: string) => {
  const node = makeNodePrintable(getChartNode())
  const callback = (blob: Blob) => uploadShareFile(blob, uploadUrl, 'image/png')
  getPngBlob(node, callback)
}

const uploadJson = (data: ISeriesDump, uploadUrl: string) => {
  const packed = pako.gzip(JSON.stringify(data))
  const blob = new Blob([packed], { type: 'application/json' })
  uploadShareFile(blob, uploadUrl, 'application/json', true)
}

const uploadData = async (response: IShareLinkResponse, data: ISeriesDump) => {
  uploadSVG(response.svgUploadUrl)
  uploadPNG(response.pngUploadUrl)
  uploadJson(data, response.jsonUploadUrl)
}

export const generateSharedLink =
  (): AsyncAction => async (dispatch: any, getState: () => IRootState) => {
    const seriesStore = getState().series
    const seriesStateDump = dumpSeriesState(seriesStore)

    const response = await createLinkShareData()
    await uploadData(response, seriesStateDump)

    dispatch(ACTIONS.setLink(response))
  }

export const updateSharedLink =
  (): AsyncAction => async (dispatch: any, getState: () => IRootState) => {
    const seriesStore = getState().series
    const seriesStateDump = dumpSeriesState(seriesStore)

    const response = await updateLinkShareData(seriesStore.link.id)
    await uploadData(response, seriesStateDump)

    dispatch(ACTIONS.setLink(response))
    dispatch(
      openToast({
        title: 'Link successfully updated.',
      })
    )
  }

export const loadSeriesFromLink =
  (linkId: string): AsyncAction =>
  async (dispatch: any) => {
    dispatch(ACTIONS.setLoadingSeriesId('LOADING_SNAPSHOT'))

    try {
      const response = await getSharedLinkData(linkId)
      const { data } = await Axios.get(response.jsonUrl, {
        headers: { 'Content-Encoding': 'gzip' },
      })

      dispatch(ACTIONS.loadSeriesDump(data))
      dispatch(ACTIONS.setLink(response))
    } catch (error) {
      if (error?.response?.status === 403) {
        dispatch(
          openToast({
            title: 'Selected series is not available.',
            subtitle: "You don't have a permissions to database which series belongs to",
            type: 'error',
          })
        )
      } else {
        // Re-throw to manage with other errors, like 500, on the global level
        throw error
      }
    } finally {
      dispatch(ACTIONS.setLoadingSeriesId(null))
    }
  }
