// eslint-disable max-lines
import classNames from 'classnames'
import * as React from 'react'

import { bindBem } from '../bem'
import { DEFAULT_GRAPH_TITLE, EDIT_TITLES_DIALOG, SIDEBAR } from '../messages'
import { COLORS } from '../services/series'
import { Button } from './Button'
import { TextArea, VerticalLabeled } from './Input'
import { ActionsBar } from './Modal'
import { ReactComponent as Rect } from 'static/rect.svg'
import { ReactComponent as SetToDefault } from 'static/set-to-default.svg'
import { Switch } from './Sidebar/Switch'

import './EditTitlesDialog.scss'

export interface IProps {
  onClose: () => void
  onSave: (title: string, names: string[], isLegendShown: boolean) => void
  series: IDataSeries[]
  titles: string[]
  title: string
  isLegendShown: boolean
}

export interface IState {
  title: string
  seriesNames: string[]
  validations: { series: boolean[]; title: boolean }
  isLegendShown: boolean
}

export class EditTitlesDialog extends React.Component<IProps, IState> {
  titleCharsLimit = 80
  seriesTitleCharsLimit = 80

  constructor(props: IProps) {
    super(props)

    const seriesNames = props.series.map(
      (s, i) => props.titles[i] || s.title || s.description
    )

    this.state = {
      seriesNames,
      validations: { series: [], title: false },
      title: props.title || '',
      isLegendShown: props.isLegendShown,
    }
  }

  onLegendToggle = () => this.setState({ isLegendShown: !this.state.isLegendShown })

  render() {
    const { block, element } = bindBem('EditTitlesDialog')
    const { series } = this.props
    const { seriesNames, validations, title, isLegendShown } = this.state

    const titleCharsLeft = this.titleCharsLimit - title.length
    const titleCounterClassNames = classNames(element('CharsCounter'), {
      [element('CharsLimitReached')]: titleCharsLeft === 0,
    })

    return (
      <div className={block()}>
        <ActionsBar>
          <Button
            text={EDIT_TITLES_DIALOG.RESET_ALL}
            style="light"
            onClick={this.resetAll}
            size="small"
          />
          <Button
            text={EDIT_TITLES_DIALOG.APPLY}
            disabled={this.isInvalid()}
            style="dark"
            onClick={this.onSave}
            size="small"
            className={element('Submit')}
          />
        </ActionsBar>

        <div className={element('Content')}>
          <VerticalLabeled
            label={'TITLE'}
            error={validations.title && EDIT_TITLES_DIALOG.SERIES_TITLE_TOO_LONG}
          >
            <TextArea
              name="title"
              postfix={
                <div
                  onClick={() => this.setTitleToDefault()}
                  className={element('RevertButton')}
                >
                  <SetToDefault />
                </div>
              }
              placeholder={DEFAULT_GRAPH_TITLE}
              value={title}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                this.setTitle(e.currentTarget.value)
              }
              error={validations.title}
            />
            <div className={`${element('Note')} row between-xs`}>
              <div>
                Use <span className={element('Pill')}>Enter</span> to break the line
              </div>
              <div className={titleCounterClassNames}>{titleCharsLeft}</div>
            </div>
          </VerticalLabeled>

          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.LEGEND}
            value={isLegendShown}
            onChange={this.onLegendToggle}
          >
            <span className={element('LegendSwitchLabel')}>
              {isLegendShown
                ? SIDEBAR.GRAPH_OPTIONS.LEGEND_ON
                : SIDEBAR.GRAPH_OPTIONS.LEGEND_OFF}
            </span>
          </Switch>

          {series.map((s, i) => {
            const hasError = validations.series[i]

            const value = seriesNames[i] || ''
            const charsLeft = this.seriesTitleCharsLimit - value.length
            const counterClassNames = classNames(element('CharsCounter'), {
              [element('CharsLimitReached')]: charsLeft === 0,
            })
            return (
              <VerticalLabeled
                label={`SERIES ${i + 1}`}
                key={`${s.id}-${i}`}
                error={hasError && EDIT_TITLES_DIALOG.SERIES_TITLE_TOO_LONG}
              >
                <TextArea
                  name={`series-name-${i}`}
                  prefix={
                    <Rect color={COLORS[s.colorIndex]} className={element('Rect')} />
                  }
                  postfix={
                    <div
                      onClick={() => this.setSeriesToDefault(i)}
                      className={element('RevertButton')}
                    >
                      <SetToDefault />
                    </div>
                  }
                  placeholder={EDIT_TITLES_DIALOG.SERIES_NAME_PLACEHOLDER}
                  value={value}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    this.setSeriesName(e.currentTarget.value, i)
                  }
                  error={hasError}
                />

                <div className={`${element('Note')} row end-xs`}>
                  <div className={counterClassNames}>{charsLeft}</div>
                </div>
              </VerticalLabeled>
            )
          })}
        </div>
      </div>
    )
  }

  private setSeriesName = (value: string, index: number) => {
    const title = value.replace('\n', '').substr(0, this.seriesTitleCharsLimit)
    const isInvalid = this.isSeriesNameInvalid(title)

    this.setState(prevState => {
      const seriesValidations = [...prevState.validations.series]
      seriesValidations[index] = isInvalid

      const seriesNames = [...prevState.seriesNames]
      seriesNames[index] = title

      return {
        seriesNames,
        validations: {
          ...prevState.validations,
          series: seriesValidations,
        },
      }
    })
  }

  private setTitle = (value: string) => {
    let title = value.substr(0, this.titleCharsLimit)
    const newLineIndex = value.indexOf('\n')
    if (newLineIndex !== -1) {
      title =
        title.substr(0, newLineIndex + 1) +
        title.substr(newLineIndex + 1, title.length).replace('\n', '')
    }
    const isInvalid = this.isSeriesNameInvalid(title)
    this.setState(() => ({
      ...this.state,
      title,
      validations: { ...this.state.validations, title: isInvalid },
    }))
  }

  private setSeriesToDefault = (index: number) => {
    this.setState(prevState => {
      const seriesNames = [...prevState.seriesNames]
      seriesNames[index] = ''
      return { seriesNames }
    })
  }

  private setTitleToDefault = () => {
    this.setState(prevState => ({
      ...prevState,
      title: '',
      validations: { title: false, ...prevState.validations },
    }))
  }

  private resetAll = () => {
    const { series } = this.props
    this.setState(() => ({
      seriesNames: series.map(s => s.title || s.description),
      title: '',
    }))
  }

  private isSeriesNameInvalid = (name: string) => name.length > 80

  private isInvalid = () =>
    this.state.validations.title || this.state.validations.series.some(x => x)

  private onSave = () => {
    this.props.onSave(this.state.title, this.state.seriesNames, this.state.isLegendShown)
    this.props.onClose()
  }
}
