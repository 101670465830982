import * as React from 'react'

import { bindBem } from '../../bem'
import * as messages from '../../messages'
import { Freq, isWeekly } from '../../services/series'
import { Button } from '../Button'
import { getSeriesLabelJSX } from './SeriesBoxTransformationLabel'
import { TransformationTypeDropdown } from './TransformationTypeDropdown'

import { ReactComponent as Check } from 'static/check.svg'
import { ReactComponent as Cross } from 'static/cross.svg'
import { ReactComponent as Swap } from 'static/swap.svg'

import './SeriesBoxTransformation.scss'

export interface IProps {
  series1: IDataSeries
  series2: IDataSeries
  transformationType: TransformationType
  onSave: () => void
  onCancel: () => void
  swapSeries: () => void
  onTransformationTypeUpdate: (transformationType: TransformationType) => void
  color: string
}

export const SeriesBoxTransformation: React.FC<IProps> = props => {
  const { series1, series2, onCancel, color, swapSeries, onSave, transformationType } =
    props
  const { element, block } = bindBem('SeriesBoxTransformation')

  const onTransformationTypeUpdate = (_transformationType: TransformationType) =>
    props.onTransformationTypeUpdate(_transformationType)

  const frequencyError = !(
    series1.frequency === series2.frequency ||
    (isWeekly(series1.frequency) && isWeekly(series2.frequency))
  )

  const typesError = series1.dataType !== series2.dataType
  const hasError = frequencyError || typesError

  const [hasAcceptedError, setAcceptedError] = React.useState(!hasError)
  let aggregation = Math.min(series1.frequency, series2.frequency) as Frequency
  if (isWeekly(aggregation)) {
    aggregation = Freq.Mon
  }
  const isErrorResolved = !hasError || hasAcceptedError
  return (
    <div className={block({ hasError: !isErrorResolved })}>
      {!isErrorResolved && (
        <ErrorBox onCancel={props.onCancel} onContinue={() => setAcceptedError(true)}>
          {typesError && (
            <p>
              {messages.SERIESBOX_TRANSFORMATION.UNIT_MISMATCH(
                series1.dataType,
                series2.dataType
              )}
            </p>
          )}
          {frequencyError && (
            <p>
              {messages.SERIESBOX_TRANSFORMATION.FREQ_MISMATCH(
                messages.AGGREGATION_LABEL[aggregation]
              )}
            </p>
          )}
        </ErrorBox>
      )}
      <div className={element('Wrapper')}>
        <div className={`${element('Stripe')} box`} style={{ backgroundColor: color }} />
        <div className={element('ContentWrapper')}>
          <div className={element('Title')}>
            {getSeriesLabelJSX(series1)}
            <TransformationTypeDropdown
              value={transformationType}
              onChange={onTransformationTypeUpdate}
            />
            {getSeriesLabelJSX(series2)}
          </div>

          <div className={`${element('ButtonContainer')} row between-xs`}>
            <div className={`${element('LeftButtons')} row middle-xs`}>
              <button
                className={`${element('Button')}`}
                onClick={swapSeries}
                disabled={!isErrorResolved}
              >
                <Swap />
              </button>
            </div>
            <div className={`${element('RightButtons')} row start-xs`}>
              <div className="box">
                <button
                  className={`${element('Button')}`}
                  onClick={onCancel}
                  disabled={!isErrorResolved}
                >
                  <Cross />
                </button>
                <button
                  className={`${element('Button')}`}
                  onClick={onSave}
                  disabled={!isErrorResolved}
                >
                  <Check />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface IErrorBoxProps {
  onCancel: () => void
  onContinue: () => void
}

export const ErrorBox: React.FC<IErrorBoxProps> = props => {
  const { block, element } = bindBem('ErrorBox')
  return (
    <div className={block()}>
      <div className={element('Content')}>{props.children}</div>
      <div className={element('Actions')}>
        <Button
          text="CANCEL"
          onClick={props.onCancel}
          size="small"
          className={element('Cancel')}
        />
        <Button text="CONTINUE" onClick={props.onContinue} size="small" style="pale" />
      </div>
    </div>
  )
}
