import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { bindBem } from '../../bem'
import { FUNCTIONS_DIALOG } from '../../messages'
import { Freq } from '../../services/series'
import { Input } from '../Input'
import { IFormProps, InnerForm } from './AdvancedFunctionUtils'
import { AutoSave } from './AutoSave'
import { errorMessages, regExps, validators } from './FnFormUtils'

const { FORMAT_VALIDATION_MESSAGE } = FUNCTIONS_DIALOG

type FrequencyType = keyof typeof regExps
const validateMatchedGroup =
  (validatorType: FrequencyType, regexpType: FrequencyType) => (value: string) =>
    validators[validatorType](regExps[regexpType])(value)

const testRegexp = (freqType: FrequencyType) => (value: string) =>
  regExps[freqType].test(value)

const getValidator = (k: FrequencyType) => {
  const types = getMatchingTypes(k)
  let validation = Yup.string().test(
    'match-full',
    FORMAT_VALIDATION_MESSAGE(
      FUNCTIONS_DIALOG.SA_END_DATE_LABEL,
      types.map(freqToFormat)
    ),
    s => types.some(t => testRegexp(t)(s))
  )
  for (const regexpType of types) {
    for (const validationType of getMatchingTypes(regexpType)) {
      if (['month', 'day'].includes(regexpType) && validationType === 'quarter') {
        continue
      }
      validation = validation.test(
        `test-${regexpType}-${validationType}`,
        errorMessages[validationType],
        validateMatchedGroup(validationType, regexpType)
      )
    }
  }

  return validation
}

export const getValidationSchema = (k: FrequencyType) => {
  const value = getValidator(k)
  return Yup.object().shape({
    value,
  })
}

export const freqToKey = (f: Frequency): FrequencyType => {
  switch (f) {
    case Freq.Annually:
      return 'year'
    case Freq.Quarterly:
      return 'quarter'
    case Freq.Monthly:
      return 'month'
    default:
      return 'day'
  }
}

const getMatchingTypes = (f: FrequencyType): FrequencyType[] => {
  const types: FrequencyType[] = ['year', 'quarter', 'month', 'day']
  switch (f) {
    case 'year':
      return types.slice(0, 1)
    case 'quarter':
      return types.slice(0, 2)
    case 'month':
      return types.slice(0, -1)
    default:
      return types
  }
}

const freqToFormat = (f: FrequencyType): string => {
  switch (f) {
    case 'year':
      return 'YYYY'
    case 'quarter':
      return 'YYYYQ'
    case 'month':
      return 'YYYYMM'
    default:
      return 'YYYYMMDD'
  }
}

export const FnValueForm = (props: IFormProps) => {
  const { state, setForm, setFormValidation, isNested } = props
  const value = state.args[0] as number
  const initialValues = { value: value !== null ? value : 12 }
  const { block, element } = bindBem('FnValueForm')
  const VALIDATION_SCHEMA = React.useMemo(
    () => getValidationSchema(freqToKey(props.frequency)),
    [props.frequency]
  )
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => setForm({ ...state, args: [+values.value] })}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ errors, values, submitForm, isValid, handleChange }) => (
        <InnerForm
          {...props}
          title="Value"
          className={block()}
          onSave={submitForm}
          isValid={isValid}
        >
          <div className="row row-xs">
            <Input
              value={values.value}
              onChange={handleChange}
              type="text"
              inputMode="numeric"
              name="value"
              error={errors.value && !!errors.value}
              pattern="[0-9]"
            />
          </div>
          {errors.value && (
            <div className="row">
              <div className={element('Error')}>{errors.value}</div>
            </div>
          )}
          {!isNested && (
            <AutoSave
              onSubmit={submitForm}
              values={values}
              isValid={isValid}
              callback={() => setFormValidation(isValid)}
            />
          )}
        </InnerForm>
      )}
    </Formik>
  )
}
