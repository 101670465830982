import * as React from 'react'

import { bindBem } from '../bem'

import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { ModalLink } from './ModalLink'
import { AvatarMenu } from './AvatarMenu'
import { Button } from './Button'
import { isFunctionApplied } from 'store/Transformations'
import { IRootState } from '../store'

import { ReactComponent as Directory } from '../static/sidebar-directory-active.svg'
import { ReactComponent as SidebarSeries } from '../static/sidebar-group.svg'
import { ReactComponent as FLetter } from 'static/f-letter.svg'
import { ReactComponent as Logo } from 'static/logo.svg'

import './MobileHeader.scss'
import { ENV, IS_PRODUCTION } from '../config'

interface IProps {
  isPhone?: boolean
}

interface IStateProps {
  hasFunction: boolean
  hasVariables: boolean
}

export const MobileHeader: React.FC<IProps & IStateProps> = ({
  hasFunction,
  hasVariables,
}) => {
  const { block, element } = bindBem('MobileHeader')
  const location = useLocation()
  const isDirectory = location.pathname === '/'
  return (
    <div className={block()}>
      <div className={`${element('Logo')}`}>
        <Logo />
        {!IS_PRODUCTION && <div className={element('EnvBadge')}>{ENV}</div>}
      </div>
      {hasVariables && (
        <ModalLink to="/functions">
          <Button
            onClick={undefined}
            style={hasFunction ? 'default' : 'text'}
            size="small"
            icon={<FLetter />}
          />
        </ModalLink>
      )}
      {isDirectory ? (
        <Link to="/series">
          <Button
            onClick={undefined}
            icon={<SidebarSeries />}
            style="text"
            size="small"
          />
        </Link>
      ) : (
        <Link to="/">
          <Button onClick={undefined} icon={<Directory />} style="text" size="small" />
        </Link>
      )}
      <AvatarMenu small />
    </div>
  )
}

function mapStateToProps(state: IRootState): IStateProps {
  const { variables } = state.series
  const hasVariables = variables.length > 0
  const allHaveFunction = hasVariables && variables.every(v => isFunctionApplied(v))
  return { hasFunction: allHaveFunction, hasVariables }
}

export default connect(mapStateToProps)(MobileHeader)
