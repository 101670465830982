import classnames from 'classnames'
import * as React from 'react'

import { bindBem } from '../../bem'
import { TOOLTIPS } from '../../messages'

import { ReactComponent as Dots } from 'static/dots.svg'
import { ReactComponent as Revert } from 'static/revert.svg'
import { ReactComponent as Line } from 'static/line.svg'
import { ReactComponent as FLetter } from 'static/f-letter.svg'
import { ReactComponent as ILetter } from 'static/i-letter.svg'
import { ReactComponent as Rho } from 'static/rho.svg'
import { ReactComponent as Log } from 'static/log.svg'
import { ReactComponent as ZLetter } from 'static/z-letter.svg'
import { ReactComponent as Add } from 'static/add.svg'

import './Buttons.scss'

const { block, element } = bindBem('ToolbarButton')

interface IProps {
  active?: boolean
  disabled?: boolean
  noBorder?: boolean
  graphButton?: boolean
  fxButton?: boolean
  logButton?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
  className?: string
  title?: string
}

export const Button = (props: IProps) => {
  const className = block({
    active: props.active,
    noBorder: props.noBorder,
    graph: props.graphButton,
    fx: props.fxButton,
    log: props.logButton,
  })

  return (
    <button
      className={classnames(className, props.className)}
      disabled={props.disabled}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  )
}

type BtnProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean
}

export const MoreOptionsButton = (props: BtnProps) => (
  <Button noBorder {...props}>
    <Dots />
  </Button>
)

export const RevertButton = (props: BtnProps) => (
  <Button noBorder {...props} title={TOOLTIPS.UNDO}>
    <Revert />
  </Button>
)

export const GraphButton = (
  props: BtnProps & { active?: boolean; icon?: JSX.Element }
) => {
  return (
    <Button graphButton {...props} className={block({ active: props.active })}>
      {props.icon || <Line />}
      <div className={element('ArrowDown')} />
    </Button>
  )
}

export const FxButton = (props: BtnProps) => (
  <Button fxButton title={TOOLTIPS.FUNCTION} {...props}>
    <FLetter />
  </Button>
)

export const InterpolationButton = (props: BtnProps) => (
  <Button title={TOOLTIPS.INTERPOLATION} {...props}>
    <ILetter />
  </Button>
)

export const CorrelationButton = (props: BtnProps) => (
  <Button {...props} title={TOOLTIPS.CORRELATION}>
    <Rho />
  </Button>
)

export const LogScaleButton = (props: BtnProps) => (
  <Button noBorder logButton {...props} title={TOOLTIPS.LOG_SCALE}>
    <Log />
  </Button>
)

export const ZoomButton = (props: BtnProps) => {
  // eslint-disable-next-line react/prop-types
  const { active, disabled } = props
  return (
    <Button
      className={block({ noBorder: true, active })}
      disabled={disabled}
      {...props}
      title={TOOLTIPS.ZOOM}
    >
      <ZLetter />
    </Button>
  )
}

export const ButtonSeparator = (props: { isCollapsed?: boolean }) => {
  const separatorBem = bindBem('ButtonSeparator')
  return (
    <span
      className={separatorBem.block({
        isCollapsed: props.isCollapsed || false,
      })}
    />
  )
}

export const TransformationButton = (
  props: BtnProps & { active?: boolean; icon?: JSX.Element }
) => {
  const icon = props.icon || <Add />
  return (
    <Button graphButton {...props} className={block({ active: props.active })}>
      {icon}
      <div className={element('ArrowDown')} />
    </Button>
  )
}

export const StatsButton = (props: BtnProps & { icon: JSX.Element }) => {
  return (
    <Button {...props}>
      {props.icon}
      <div className={element('ArrowUp')} />
    </Button>
  )
}
