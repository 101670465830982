import { ITextBlockConfig, ITitleTextBlockConfig } from './hd3-text-block'
import { CSS_CONSTANTS } from '../constants'

export interface IChartConfig {
  titleTopMargin: number
  chartTopMargin: number
  xAxisLabelHeight: number
  corrLabelHeight: number
}

export interface IConfig {
  title: ITitleTextBlockConfig
  legend: ITextBlockConfig
  chart: IChartConfig
  yAxisLabelLeft: ITextBlockConfig
  yAxisLabelRight: ITextBlockConfig
}

export const getChartConfig = (
  width: number,
  colorIndexes: number[],
  titleText: string
): IConfig => {
  const title: ITitleTextBlockConfig = {
    id: 'title-config',
    screenWidth: width,
    margin: { top: 30, left: 70, right: 70 },
    columnCount: 1,
    textWrapping: true,
    columnMargin: 0,
    legendRectWidth: 0,
    oneLineHeight: 21,
    fontSize: 16,
    fontWeight: 500,
    fontColor: CSS_CONSTANTS.primary700,
    printable: !!titleText,
  }

  const maxWidth = 1400
  const legendWidth = Math.min(width, maxWidth)
  const marginX = (width - legendWidth) / 2 + 30

  const legend: ITextBlockConfig = {
    id: 'legend-config',
    screenWidth: width,
    margin: { top: 14, left: marginX, right: marginX },
    columnCount: 2,
    colorIndexes,
    textWrapping: false,
    columnMargin: 100,
    legendRectWidth: 16,
    oneLineHeight: 15,
    fontSize: 12,
    fontWeight: 500,
  }

  const chart: IChartConfig = {
    titleTopMargin: 10,
    chartTopMargin: 10,
    xAxisLabelHeight: 14,
    corrLabelHeight: 15,
  }

  const yAxisLabel = {
    screenWidth: 300,
    margin: { top: 14, left: 75, right: 75 },
    columnCount: 1,
    textWrapping: false,
    columnMargin: 0,
    legendRectWidth: 0,
    oneLineHeight: 15,
    fontSize: 11,
    fontWeight: 700,
  }

  const yAxisLabelLeft: ITextBlockConfig = {
    ...yAxisLabel,
    id: 'x-axis-label-left',
  }

  const yAxisLabelRight: ITextBlockConfig = {
    ...yAxisLabel,
    id: 'x-axis-label-right',
  }

  return {
    title,
    legend,
    chart,
    yAxisLabelLeft,
    yAxisLabelRight,
  }
}
