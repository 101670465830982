import * as React from 'react'

import { bindBem } from '../../bem'

import './SidebarButton.scss'

interface IProps {
  label: string
  selected: boolean
  disabled: boolean
  onClick: () => void
}

const SidebarButton = (props: IProps) => {
  const { block } = bindBem('SidebarButton')
  const { selected, disabled } = props
  return (
    <div className={block({ selected, disabled })} onClick={props.onClick}>
      {props.label}
    </div>
  )
}

export default SidebarButton
