// eslint-disable max-lines
import * as _ from 'lodash'
import * as yup from 'yup'

import { DUPLICATED_VALUE, TICKS_SCALE_FORM } from './messages'

interface IIndexedUser extends IUser {
  [key: string]: any
}

interface IDuplicatesAcc {
  [key: string]: number[]
}

// eslint-disable no-invalid-this
yup.addMethod(yup.array, 'aLimit', function (message, limit) {
  return this.test('aLimit', message, function (list) {
    if (limit && list?.length > limit) {
      return this.createError({
        message,
      })
    }
    return true
  })
})

yup.addMethod(yup.array, 'nonEmptyStrings', function (message) {
  return this.test('nonEmptyStrings', message, function (list) {
    const nonEmptyStringsCount = list.filter(el => !!el).length

    if (nonEmptyStringsCount > 0) {
      return true
    }

    return this.createError({
      message,
    })
  })
})

yup.addMethod(yup.array, 'uniqueKey', function (message, key) {
  return this.test('uniqueKey', message, function (list) {
    const aggregate: IDuplicatesAcc = list.reduce(
      (acc: IDuplicatesAcc, obj: IIndexedUser, index: number) => {
        const keyValue = obj[key]
        acc[keyValue] = [...(acc[keyValue] || []), index]
        return acc
      },
      {}
    )

    const duplicates = Object.entries(aggregate).filter(
      ([, indexes]) => indexes.length > 1
    )
    const lastOne: [string, number[]] = duplicates.slice(-1)[0]

    if (lastOne) {
      const value = lastOne[0]
      const path = lastOne[1].slice(-1)[0]

      return this.createError({
        message: DUPLICATED_VALUE(key, value),
        path: `[${path}].${key}`,
      })
    }
    return true
  })
})
// eslint-enable no-invalid-this

export const getStatistics = (values: number[]) => {
  const result: SMap<number> = {}
  if (!values || values.length === 0) {
    return result
  }

  const max = _.max(values)
  const min = _.min(values)

  const length = values.length
  const sum = _.sum(values)
  const mean = sum / length

  const sum_dev_squared = values.reduce((acc, cur) => acc + Math.pow(cur - mean, 2), 0)

  const sd = Math.sqrt(sum_dev_squared / length)

  const middle = Math.floor(values.length / 2)
  values.sort((a, b) => a - b) // Explicit numerical sort
  const median =
    values.length / 2 > middle
      ? values[middle]
      : (values[middle] + values[middle - 1]) / 2

  result.MIN = min
  result.MAX = max
  result.MEDIAN = median
  result.MEAN = mean
  result.SD = sd

  return result
}

export const buildTitle = (data: any): string => {
  const result = []
  const title = data.title?.titletext
  const title2 = getArray(data.title2)
    .map(t => (t.title2text['#text'] || t.title2text).trim())
    .join(', ')
  const title3 = getArray(data.title3)
    .map((t: any) => (t.title3text['#text'] || t.title3text).trim())
    .join(', ')
  if (title) {
    result.push((title['#text'] || title).trim())
  }
  if (title2.length > 0) {
    result.push(title2)
  }
  if (title3.length > 0) {
    result.push(title3)
  }

  return result.join(' - ')
}

export const getArray = (data: any) => (data ? (Array.isArray(data) ? data : [data]) : [])

export const getDbSeriesId = (entry: { id: string; databaseId: string }) =>
  `${entry.id}@${entry.databaseId}`

export const chunkBy = <T>(arr: T[], predicate: (e: T) => boolean): T[][] => {
  const result: T[][] = []

  let currIndex = 0
  while (currIndex < arr.length) {
    const chunk = _.takeWhile(arr.slice(currIndex), predicate)
    currIndex += chunk.length
    currIndex += _.takeWhile(arr.slice(currIndex), elem => !predicate(elem)).length

    if (chunk.length) {
      result.push(chunk)
    }
  }
  return result
}

export const splitValues = (val: string) => {
  val = val.trim()
  if (val === '') {
    return []
  }
  const schema = yup.number()
  const result = val
    .trim()
    .split(/,/)
    .map(v => v.trim())
  if (result.filter(v => !schema.isValidSync(v as unknown as number)).length > 0) {
    return ''
  }
  return result.map(v => parseFloat(v))
}
const NUMBER_ARR_SCHEMA = yup.array().of(yup.number())

export const getScalesSchemas = () => ({
  minmax: yup.object<{ min: number; max: number }>().shape({
    min: yup
      .number()
      .required(TICKS_SCALE_FORM.MIN_SHOULD_BE_A_NUMBER)
      .typeError(TICKS_SCALE_FORM.SHOULD_BE_A_NUMBER),
    max: yup
      .number()
      .required(TICKS_SCALE_FORM.MAX_SHOULD_BE_A_NUMBER)
      .typeError(TICKS_SCALE_FORM.SHOULD_BE_A_NUMBER),
  }),
  incremental: yup.object<{ values: number[] }>().shape({
    values: yup
      .mixed()
      .required(TICKS_SCALE_FORM.IS_REQUIRED)
      .transform(splitValues)
      .test('is-arr', TICKS_SCALE_FORM.IS_NUMBER_ARRAY, value =>
        NUMBER_ARR_SCHEMA.isValidSync(value)
      )
      .test(
        'has-more-than-one',
        TICKS_SCALE_FORM.MORE_THAN_ONE_TICK,
        value => value !== undefined && value.length > 1
      ),
  }),
})

const NIV = 6

export const calculateScale = (min: number, max: number, ticks: number) => {
  let result = _calculateScale(min, max, ticks)
  const rescale = result.min <= -1000 || Math.abs(result.max) >= 10000 ? 1000 : 1
  if (rescale === 1000) {
    result = _calculateScale(min / 1000, max / 1000, ticks)
  }

  return { min: result.min * rescale, max: result.max * rescale }
}

export const calculateAxis = (min: number, max: number, ticksCount?: number) => {
  const reversed = min > max
  if (reversed) {
    ;[min, max] = [max, min]
  }
  if (min === max) {
    return { ticks: 5, ...calculateScale(min, max, 5) }
  }
  let result: { min: number; max: number; ticks: number }
  if (ticksCount) {
    result = { ticks: ticksCount, ..._calculateScale(min, max, ticksCount) }
  } else {
    const scales = [6, 5, 4].map(ticks => ({
      ticks,
      ...calculateScale(min, max, ticks),
    }))
    result = _.minBy(scales, res => res.max - res.min)
  }
  if (reversed) {
    result = { ...result, min: result.max, max: result.min }
  }

  return result
}

const _calculateScale = (min: number, max: number, ticks: number) => {
  let smin = 0
  let smax = 0
  let step = 0

  if (min > max) {
    smin = 0
    smax = ticks
    step = 0
  } else if (max === min) {
    min -= 2
    max = min + ticks - 1
  } else if (min < 0.001 && max < 0.001) {
    min -= 0.003
    max += 0.003
  } else if (Math.abs(min - max) < 0.00000001) {
    min -= 2
    max = min + ticks - 1
  } else if (Math.abs(min - max) < 0.0001) {
    min -= 0.5
    max += 0.5
  }
  let a = (max - min) / ticks
  let z = 1

  const eps = 0.000001 * a
  while (a > 10) {
    a /= 10
    z *= 10
  }
  while (a < 1 && a !== 0) {
    a *= 10
    z /= 10
  }

  let i = 0
  const vi = [1, 2, 2.5, 4, 5, 7.5, 10, 15]
  for (i = 0; i < NIV; i++) {
    if (a - eps < vi[i]) {
      break
    }
  }
  for (;;) {
    step = z * vi[i]
    if (step < eps) {
      break
    }
    if (step === 0 && eps === 0) {
      break
    }

    smin = Math.floor(min / step) * step
    if (smin > min) {
      smin -= step
    } else if (smin + step < min + eps) {
      smin += step
    }
    smax = smin + ticks * step
    if (smax >= max) {
      break
    }
    i++
  }

  while (min - smin + step < smax - max - step && smin >= step) {
    smin -= step
    smax -= step
  }

  return {
    min: smin,
    max: smax,
  }
}

export function generateTicks(domain: number[], count: number) {
  if (count === 1) {
    return [domain[0]]
  }
  const [last] = domain.slice(-1)
  const first = domain[0]
  const step = (last - first) / (count - 1)
  const values = new Array(count - 2).fill(1).map((_e, i) => {
    return first + step * (i + 1)
  })
  return [first, ...values, last]
}

/*
xmin      2.345
xmax     76.244

scale
number of ticks
n_nmk                  4

smin      0.000
smax     80.000

ymk       double [26]
[0]          80.000
[1]          60.000
[2]          40.000
[3]          20.000
[4]          0.000
[5]          0.000
[6]          0.000
 */

export function generateLogTicks(
  originalRange: number[],
  domain: number[],
  count: number
) {
  const NMKMAX = 25
  const YLOGMIN = 0.001
  const log10 = Math.log10
  const xmin = originalRange[0]
  let smin = domain[0]
  const ticks = generateTicks(domain, count + 1).reverse()
  const pymk = ticks.concat(new Array(NMKMAX + 1 - ticks.length).fill(0))

  let nmk = count

  if (pymk[nmk] < YLOGMIN) {
    // check if the lower scale value is less than .001
    let xx = xmin
    let dp10 = 0.01
    let kp10 = -2

    while (xx > dp10) {
      dp10 *= 10
      kp10++
    }
    dp10 /= 10
    kp10--

    if (kp10 >= 0) {
      xx = Math.floor((xx / dp10) * dp10)
    }
    pymk[nmk] = smin = xx // this is where the lower scale value gets adjusted
  }

  while (
    log10(pymk[nmk - 1] / pymk[nmk]) > 0.4 * log10(pymk[0] / pymk[nmk]) &&
    count < NMKMAX
  ) {
    pymk[nmk + 1] = pymk[nmk] // move lower scale down the array
    pymk[nmk] = pymk[nmk - 1] / 2 // insert new value between lower value and the the one before it
    count = ++nmk // this is where the number of ticks get incremented
  }

  return { domain: [smin, domain[1]], count, ticks: pymk }
}

export function filterIncorrectTicks(ticks: number[], isNegative: boolean) {
  const predicate = (a: number, b: number) => (isNegative ? a > b : a < b)
  let previous = ticks[0]
  const result = [previous]
  for (let i = 1; i <= ticks.length; i++) {
    const current = ticks[i]
    if (!predicate(previous, current)) {
      continue
    }
    result.push(current)
    previous = current
  }
  return result
}

/* It mostly follows desktop app's implementation:
 * If there should be more ticks than provided in `v1, v2, v3`, infer them based on step=v3-v2
 * always try to start with `v1`
 * filter out "incorrect" values, i.e. `1, 2, 1, 3` => `1, 2, 3`
 * desktop implenentation has many, many more quirks.
 * Most of them seem to be not intended, so implementation is not 100% the same
 */
export function generateIncrementalTicks(originalTicks: number[], count: number) {
  if (originalTicks.length < 2) {
    throw Error('there should be at least two ticks')
  }
  const [nextToLast, last] = originalTicks.slice(-2)
  const step = last - nextToLast
  const isNegative = step < 0

  const ticks = filterIncorrectTicks(originalTicks, isNegative)

  if (ticks.length >= count) {
    return ticks.slice(0, count)
  }
  const reference = _.last(ticks)
  const missingCount = count - ticks.length
  const upper = reference + step * missingCount
  const missingTicks = generateTicks([last + step, upper], missingCount)
  return [...ticks, ...missingTicks]
}

export const isMobile = () =>
  window.matchMedia('(hover: none) and (pointer: coarse)').matches

export const reorder = <T>(arr: T[], index: number, targetIndex: number) => {
  const shallowCopy = [...arr]
  const [elem] = shallowCopy.splice(index, 1)
  return [...shallowCopy.slice(0, targetIndex), elem, ...shallowCopy.slice(targetIndex)]
}

export const isMacintosh = () => navigator.platform.includes('Mac')

function* _zip<U, V>(arr1: U[], arr2: V[]) {
  for (let i = 0; i < arr1.length && i < arr2.length; i++) {
    yield [arr1[i], arr2[i]]
  }
}

export const zip = <U, V>(arr1: U[], arr2: V[]) => Array.from(_zip(arr1, arr2))

export const toUTC = (date: Date | null) =>
  !date
    ? date
    : new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        )
      )
