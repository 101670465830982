import { ENV, API_URLS } from '../config'
import Axios, { AxiosError, Cancel } from 'axios'

export const CLIENT = (() => {
  const headers: SMap<string> = {}

  const client = Axios.create({
    baseURL: API_URLS[ENV],
    headers,
    withCredentials: true,
  })

  return client
})()

export interface RequestError extends AxiosError, Cancel {
  __CANCEL__: boolean
}
