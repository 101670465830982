import * as React from 'react'
import enhanceWithClickOutside from 'react-click-outside'

import { bindBem } from '../../bem'
import { Button } from '../Button'

import './HierarchyPopover.scss'

import { ReactComponent as Close } from '../../static/close.svg'
import { ReactComponent as SidebarHierarchy } from '../../static/sidebar-hierarchy-entry.svg'

export interface IProps {
  expanded: boolean
  entries: IDirectoryEntry[]
  onNavigateBack: (levels: number) => void
  onClose: () => void
  onFootnoteClick: () => void
}

export class BaseHierarchyPopover extends React.Component<IProps> {
  render() {
    const { entries } = this.props
    if (!entries.length) {
      return null
    }
    const length = entries.length - 1
    const { block, element } = bindBem('HierarchyPopover')
    const { onNavigateBack } = this.props
    return (
      <div className={block({ expanded: this.props.expanded })}>
        <div className={element('Title')} onClick={() => onNavigateBack(length + 1)}>
          Data Directory
        </div>

        {entries.map((entry, i) => (
          <HierarchyPopoverEntry
            key={entry.id}
            marginLeft={i * 12}
            label={entry.description}
            onClick={() => length - i > 0 && onNavigateBack(length - i)}
          />
        ))}

        <Button
          icon={<Close />}
          className="Hierarchy"
          size="small"
          onClick={this.props.onClose}
        />
      </div>
    )
  }
}

export const HierarchyPopover = enhanceWithClickOutside(BaseHierarchyPopover)

export const HierarchyPopoverEntry = (props: {
  label: string
  onClick: () => void
  marginLeft: number
}) => (
  <div
    className="HierarchyPopover__Entry"
    style={{ marginLeft: props.marginLeft }}
    onClick={props.onClick}
  >
    <SidebarHierarchy />
    <div>{props.label}</div>
  </div>
)
