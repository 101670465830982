import * as React from 'react'
import { bindBem } from '../bem'

import { SIDEBAR } from '../messages'

import { ReactComponent as Rearrange } from 'static/rearrange.svg'
import { ReactComponent as Transformation } from 'static/transformation.svg'

import './DragAndDropTooltip.scss'

interface IProps {
  hideDragAndDropTooltip: () => void
}

export const DragAndDropTooltip: React.FC<IProps> = props => {
  const { block, element } = bindBem('DragAndDropTooltip')
  return (
    <div className={block()}>
      <div className={element('Triangle')} />
      <div className={element('Header')}>{SIDEBAR.TOOLTIPS.DRAG_AND_DROP.HEADER}</div>
      <div className={element('HeightRow')}>
        <div>
          <div className={element('Title')}>
            {SIDEBAR.TOOLTIPS.DRAG_AND_DROP.REARRANGE}
          </div>
          <div className="row center-xs">
            <Rearrange />
          </div>
        </div>
        <div>
          <div className={element('Title')}>
            {SIDEBAR.TOOLTIPS.DRAG_AND_DROP.TRANSFORMATION}
          </div>
          <div className="row center-xs">
            <Transformation />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <button className={element('Button')} onClick={props.hideDragAndDropTooltip}>
          {SIDEBAR.TOOLTIPS.DRAG_AND_DROP.BUTTON}
        </button>
      </div>
    </div>
  )
}
