import * as React from 'react'

import { bindBem } from '../../bem'
import {
  getTransformationLabel,
  isSeries,
  transformationTypes,
} from '../../services/series'

import { ReactComponent as Add } from 'static/add.svg'
import { ReactComponent as Subtract } from 'static/subtract.svg'
import { ReactComponent as Multiply } from 'static/multiply.svg'
import { ReactComponent as Divide } from 'static/divide.svg'
import { ReactComponent as Percent } from 'static/percent.svg'

import './SeriesBoxTransformationLabel.scss'

type Layout = 'dark' | 'light'

const labelBlock = bindBem('SeriesBoxTransformationLabel').block

const Box: React.FunctionComponent<{ layout: Layout }> = props => (
  <div className={labelBlock({ isBox: true, isLight: props.layout === 'light' })}>
    {props.children}
  </div>
)

const TransformationBox: React.FC<{
  type: TransformationType
  layout?: Layout
}> = props => {
  const icons = {
    ADD: <Add />,
    SUBTRACT: <Subtract />,
    MULTIPLY: <Multiply />,
    DIVIDE: <Divide />,
    PERCENT: <Percent />,
  }

  return (
    <div
      className={labelBlock({
        isSign: true,
        isLight: props.layout === 'light',
      })}
    >
      {icons[props.type]}
    </div>
  )
}

const TransformationLabelJSX: React.FC<{
  transformation: TransformationOrSeries
  layout?: Layout
}> = props => {
  const { transformation, layout } = props
  if (isSeries(transformation)) {
    return (
      <Box layout={layout}>
        {transformation.seriesId}@{transformation.databaseId}
      </Box>
    )
  }
  if (
    transformationTypes.includes(transformation.func) &&
    transformation.args.length > 1
  ) {
    const arg0 = transformation.args[0] as TransformationOrSeries
    const arg1 = transformation.args[1] as TransformationOrSeries
    const transformationType = transformation.func as TransformationType
    return (
      <>
        <div
          className={labelBlock({
            isBracket: true,
            isLight: layout === 'light',
          })}
        >
          (
        </div>
        <TransformationLabelJSX transformation={arg0} layout={layout} />
        <TransformationBox type={transformationType} layout={layout} />
        <TransformationLabelJSX transformation={arg1} layout={layout} />
        <div
          className={labelBlock({
            isBracket: true,
            isLight: layout === 'light',
          })}
        >
          )
        </div>
      </>
    )
  }
  return <Box layout={layout}>{getTransformationLabel(transformation)}</Box>
}

export const getSeriesLabelJSX = (series: IDataSeries, layout?: Layout) =>
  series.transformation ? (
    <TransformationLabelJSX transformation={series.transformation} layout={layout} />
  ) : (
    <Box layout={layout}>
      {series.id}@{series.databaseId}
    </Box>
  )
