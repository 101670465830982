import * as React from 'react'

import { DropdownButton } from '../Sidebar/DropdownButton'
import { RadioGroup, Option } from '../RadioGroup'
import { StatsButton } from '../Sidebar/Buttons'
import { bindBem } from '../../bem'
import { COLORS, getSeriesLabel } from '../../services/series'

import { ReactComponent as Rect } from 'static/rect.svg'

import './SeriesDropdown.scss'

interface IProps {
  variables: IDataSeries[]
  selectedSeries: number
  onChange: (option: number) => void
}

export class SeriesDropdown extends React.PureComponent<IProps> {
  render = () => {
    const { variables, selectedSeries, onChange } = this.props

    const { block } = bindBem('SeriesDropdown')

    const Button = (props: { onClick: () => void }) => (
      <StatsButton
        icon={<Rect style={{ color: COLORS[variables[selectedSeries].colorIndex] }} />}
        {...props}
        active={false}
      />
    )

    const wrapperRef = React.createRef<DropdownButton>()

    return (
      <div className={block()}>
        <DropdownButton buttonRenderer={Button} stickTo="left" ref={wrapperRef} top>
          <RadioGroup
            className="SeriesDropdownItems"
            value={selectedSeries.toString()}
            onChange={val => {
              onChange(Number(val))
              wrapperRef.current.togglePortal()
            }}
          >
            {variables.map((v, i) => (
              <Option key={v.colorIndex} value={i.toString()}>
                <Rect className="Rect" style={{ color: COLORS[v.colorIndex] }} />
                {getSeriesLabel(v)}
              </Option>
            ))}
          </RadioGroup>
        </DropdownButton>
      </div>
    )
  }
}
