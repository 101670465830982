import * as React from 'react'

import { Button } from '../Button'
import { MoreOptionsButton } from './Buttons'
import { CollapsedDropdownButton } from './DropdownButton'
import { GRAPH, SCATTER_MENU } from '../../messages'
import { bindBem } from '../../bem'
import {
  isWeekly,
  Freq,
  getSeriesLabel,
  isFrequencyMismatch,
  isCorrLabelShown,
  AGG_NOT_ALLOWED,
} from '../../services/series'
import { AccordionSwitchGroup } from './AccordionGroup'
import { RadioGroup, Option } from '../RadioGroup'
import { Switch } from './Switch'

import { ReactComponent as Rho } from 'static/rho.svg'

import './ScatterCorrelationBox.scss'

export interface IProps {
  variables: IDataSeries[]
  scatter: boolean
  correlation: ICorrelation
  scatterSettings: IScatterSettings
  onAggregate: (series: IDataSeries, f: Aggregation) => void
  setRegressionType: (t: RegressionType) => void
  setScatterTimeline: (enabled: boolean) => void
  setAxisType: (type: ScatterAxisType) => void
  onCancel: () => void
}

export const ScatterCorrelationBox: React.FC<IProps> = props => {
  const { block, element } = bindBem('ScatterBox')
  const [v1, v2] = props.variables.slice(0, 2).sort((a, b) => a.frequency - b.frequency)
  const notEnoughVariables = v2 === undefined
  const [f1, f2]: Frequency[] = notEnoughVariables
    ? [10, 10]
    : [v1.frequency, v2.frequency]
  const frequencyMismatch = notEnoughVariables || isFrequencyMismatch(f1, f2)
  const aggregation: Aggregation = isWeekly(f1) ? Freq.Mon : (f1 as Aggregation)
  const isAggregationAllowed = v2?.aggregationType !== AGG_NOT_ALLOWED
  const varMismatchHint = props.correlation.enabled
    ? SCATTER_MENU.CORRELATION_VAR_MISMATCH_HINT
    : SCATTER_MENU.SCATTER_VAR_MISMATCH_HINT
  const corrValue = props.correlation.value
  const showCorrelation = isCorrLabelShown(props.variables, props.correlation)
  return (
    <div className={block({ error: frequencyMismatch || notEnoughVariables })}>
      <div className={`row row-xs start-xs ${element('Titles')}`}>
        {props.scatter && (
          <div className={element('ScatterPlotPill')}>
            <div className={element('TitlePill')}>{SCATTER_MENU.SCATTERED_GRAPH}</div>
            <ScatterSettingsDropdown
              {...props.scatterSettings}
              setRegressionType={props.setRegressionType}
              setScatterTimeline={props.setScatterTimeline}
              setAxisType={props.setAxisType}
            />
          </div>
        )}
        {props.correlation.enabled && (
          <div className={element('TitlePill')}>
            {SCATTER_MENU.CORRELATION}
            {showCorrelation && (
              <span className={element('CorrelationValue')}>
                (<Rho />= {corrValue.toFixed(2)})
              </span>
            )}
          </div>
        )}
      </div>
      <div className={element('Description')}>{SCATTER_MENU.DESCRIPTION}</div>
      {notEnoughVariables && (
        <div className={element('Error')}>
          <p>{SCATTER_MENU.NOT_ENOUGH_VARS}</p>
        </div>
      )}
      {!notEnoughVariables && frequencyMismatch && isAggregationAllowed && (
        <div className={element('Error')}>
          <p>{SCATTER_MENU.VAR_MISMATCH}</p>
          <p>
            {varMismatchHint} {getSeriesLabel(v2)} {SCATTER_MENU.VAR_MISMATCH_TO}{' '}
            {GRAPH[v1.frequency]}
          </p>
          <div className="row row-end">
            <Button
              text={SCATTER_MENU.CANCEL}
              onClick={props.onCancel}
              size="small"
              style="default"
            />
            <Button
              text={SCATTER_MENU.AGGREGATE}
              onClick={() => props.onAggregate(v2, aggregation)}
              size="small"
              style="pale"
            />
          </div>
        </div>
      )}
      {!notEnoughVariables && frequencyMismatch && !isAggregationAllowed && (
        <div className={element('Error')}>
          <p>{SCATTER_MENU.VAR_MISMATCH}</p>
          <p>
            {getSeriesLabel(v2)} {SCATTER_MENU.CANT_BE_AGGREATED}
          </p>
          <div className="row row-end">
            <Button
              text={SCATTER_MENU.CANCEL}
              onClick={props.onCancel}
              size="small"
              style="default"
            />
          </div>
        </div>
      )}
      {props.children}
    </div>
  )
}

const ScatterOptionsButton: React.FC<{
  onClick: () => void
  active: boolean
}> = props => <MoreOptionsButton {...props} className="ScatterOptionsButton" />

interface IScatterSettingsProps extends IScatterSettings {
  setRegressionType: IProps['setRegressionType']
  setScatterTimeline: IProps['setScatterTimeline']
  setAxisType: IProps['setAxisType']
}

const ScatterSettingsDropdown: React.FC<IScatterSettingsProps> = props => {
  const toggleOpen = React.useCallback(
    (isOpen: boolean) => props.setRegressionType(isOpen ? 'linear' : 'none'),
    []
  )
  return (
    <CollapsedDropdownButton buttonRenderer={ScatterOptionsButton}>
      <ul className="ScatterSettingsDropdown">
        <li>
          <AccordionSwitchGroup
            title={SCATTER_MENU.REGRESSION_LINE}
            isOpen={props.regressionType !== 'none'}
            toggleOpen={toggleOpen}
          >
            <RadioGroup value={props.regressionType} onChange={props.setRegressionType}>
              <Option value="linear">{SCATTER_MENU.REGRESSION_LINEAR}</Option>
              <Option value="exponential">{SCATTER_MENU.REGRESSION_EXP}</Option>
              <Option value="logarithmic">{SCATTER_MENU.REGRESSION_LOG}</Option>
            </RadioGroup>
          </AccordionSwitchGroup>
        </li>
        <li className="separator" />
        <li>
          <Switch
            value={props.isTimelineEnabled}
            title={SCATTER_MENU.TIME_LINE}
            onChange={props.setScatterTimeline}
          />
        </li>
        <li className="separator" />
        <li>
          <h4>{SCATTER_MENU.TYPE_OF_AXIS}</h4>
          <RadioGroup value={props.axisType} onChange={props.setAxisType}>
            <Option value="left">{SCATTER_MENU.LEFT_AXIS}</Option>
            <Option value="both">{SCATTER_MENU.LEFT_AND_RIGHT_AXIS}</Option>
          </RadioGroup>
        </li>
      </ul>
    </CollapsedDropdownButton>
  )
}
