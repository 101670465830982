import * as React from 'react'
import { Base64 } from 'js-base64'
import RadioButton from '../RadioButton'
import { RadioGroup } from '../RadioGroup'
import { InputCopy } from '../InputCopy'
import { bindBem } from '../../bem'
import { getAggregation } from '../../store/Transformations'
import { getZoomRange } from '../../services/hd3'
import { IRootState } from '../../store'
import { connect } from 'react-redux'
import { CLIENT } from '../../api/config'
import { SIDEBAR } from '../../messages'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Modal } from '../Modal'
import { ApiDialog } from './ApiDialog'

import './APILinkShare.scss'
import { buildMathPayload } from '../../api/series'
import { MATH_URL } from '../../api/endpoints'

const EXPORT_API_DATA = SIDEBAR.EXPORT_TAB.EXPORT_API_DATA

const ApiTypes = ['csv', 'json']

export interface IProps {
  apiToken: string
  showHelp: boolean
  toggleHelp: () => void
}

interface IState {
  apiType: string
  isPayloadCopied: boolean
}

export interface IStateProps {
  variables: IDataSeries[]
  endZoomDate: Date
  graphZoom: Zoom
}

export class APILinkShare extends React.Component<IProps & IStateProps, IState> {
  state: IState = {
    apiType: ApiTypes[0],
    isPayloadCopied: false,
  }

  render = () => {
    const { apiType, isPayloadCopied } = this.state
    const { block, element } = bindBem('APILinkShare')
    const payload = JSON.stringify(this.getApiPayload(), null, 2)
    const base64Url = this.getBase64APILink()

    return (
      <div className={block()}>
        <RadioGroup onChange={this.setApiType} value={apiType}>
          <RadioButton value={ApiTypes[0]}>.CSV</RadioButton>
          <RadioButton value={ApiTypes[1]}>.JSON</RadioButton>
        </RadioGroup>

        <div className="row" key={this.getApiLink()}>
          <span className={element('Label')}>{EXPORT_API_DATA.URL}</span>
          <InputCopy value={base64Url} />
        </div>

        <div className="row">
          <span className={element('Label')}>{EXPORT_API_DATA.PAYLOAD}</span>
          <div className={element('Payload')}>
            <textarea disabled value={payload} />
            <CopyToClipboard text={payload}>
              <a className={element('CopyPayload')} onClick={this.setPayloadCopied}>
                {isPayloadCopied ? SIDEBAR.COPIED : SIDEBAR.COPY}
              </a>
            </CopyToClipboard>
          </div>
        </div>

        <Modal isOpen={this.props.showHelp} onClose={this.props.toggleHelp}>
          <ApiDialog
            onClose={this.props.toggleHelp}
            apiLink={this.getApiLink()}
            payload={payload}
          />
        </Modal>
      </div>
    )
  }

  private setApiType = (apiType: string) =>
    this.setState({ apiType, isPayloadCopied: false })

  private getApiLink = () => {
    const token = this.props.apiToken
    const format = this.state.apiType
    return `${CLIENT.defaults.baseURL}${MATH_URL}?format=${format}&token=${token}`
  }

  private getBase64APILink = () => {
    const baseLink = this.getApiLink()
    const payload = this.getApiPayload()
    const base64Payload = Base64.encodeURL(JSON.stringify(payload))
    return `${baseLink}&payload=${base64Payload}`
  }

  private seriesToString = (series: IDataSeries): ISingleSeries => ({
    seriesId: series.id,
    databaseId: series.databaseId,
  })

  private getApiPayload = (): IMathPayload[] => {
    const { variables, endZoomDate, graphZoom } = this.props
    const range = getZoomRange(variables, endZoomDate, graphZoom)
    return this.props.variables.map(series =>
      buildMathPayload(
        series.transformation || this.seriesToString(series),
        getAggregation(series),
        series.isInterpolated,
        ...range
      )
    )
  }

  private setPayloadCopied = () => this.setState({ isPayloadCopied: true })
}

const mapStateToProps = (state: IRootState): IStateProps => ({
  variables: state.series.variables,
  endZoomDate: state.series.seriesSettings.endZoomDate,
  graphZoom: state.series.seriesSettings.graphZoom,
})

export default connect(mapStateToProps)(APILinkShare)
