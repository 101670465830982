import React from 'react'
import Draggable, { DraggableEventHandler } from 'react-draggable'

import { bindBem } from '../../bem'
import { getTransformationLabel, isSeries } from '../../services/series'
import { isFunction } from '../../store/Transformations'
import { Droppable } from '../Droppable'

import { ReactComponent as CloseSm } from 'static/close-sm.svg'
import { ReactComponent as Hamburger } from 'static/hamburger.svg'

import './FunctionBar.scss'

export interface IProps {
  transformation: TransformationOrSeries
  onRemoveFunction: (t: ITransformation) => void
  onReplace: (l: ITransformation, r: ITransformation) => void
}

export interface IState {
  drag?: ITransformation
  position: { x: number; y: number }
}

export class FunctionBar extends React.Component<IProps> {
  state: IState = { drag: null, position: { x: 0, y: 0 } }
  private block = bindBem('FunctionBar').block
  private element = bindBem('FunctionBar').element

  render() {
    return (
      <div className={this.block()}>
        {<this.Transformation t={this.props.transformation} />}
      </div>
    )
  }
  Transformation = ({ t }: { t: TransformationOrSeries }) => {
    if (isSeries(t) || !isFunction(t)) {
      return <this.Series s={t} key={t.seriesId} />
    }
    let buff: React.ReactChild[] = []
    buff.push(<this.Func f={t} key={t.func} />)
    buff.push('(')
    const Transformation = this.Transformation
    t.args.forEach((arg, i) => {
      const key = `${arg}-${i}`
      if (typeof arg === 'object') {
        const result = <Transformation key={key} t={arg} />
        buff = buff.concat(result)
      } else {
        buff.push(<div key={key}>{arg}</div>)
      }
      buff.push(',')
    })
    if (buff[buff.length - 1] === ',') {
      buff.pop()
    }

    buff.push(')')
    return <React.Fragment>{buff}</React.Fragment>
  }

  private Series = ({ s }: { s: TransformationOrSeries }) => (
    <div className={this.element('Series')}>{getTransformationLabel(s)}</div>
  )
  private Func = ({ f }: { f: ITransformation }) => {
    const disabled = f.func === 'sa' || f.func === 'FX'
    const { drag, position } = this.state
    const isDragging = drag === f
    const disabledCls = disabled ? this.element('Disabled') : ''
    return (
      <Droppable
        onDrop={disabled ? undefined : () => this.onDrop(f)}
        isDroppable={!!drag && !disabled}
      >
        <div
          style={{ zIndex: isDragging ? 10 : 0 }}
          className={this.element('FuncWrapper')}
        >
          <Draggable
            axis="both"
            position={isDragging ? position : { x: 0, y: 0 }}
            defaultClassNameDragging={this.element('DraggingFunc')}
            onStart={() => this.onDragStart(f)}
            onDrag={this.onDrag}
            onStop={this.onDragStop}
            cancel=".close"
            disabled={disabled}
          >
            <div className={`${this.element('Func')} ${disabledCls}`}>
              {!disabled && (
                <div className={this.element('Handler')}>
                  <Hamburger />
                </div>
              )}
              {f.func}

              {!disabled && (
                <div className="close" onClick={() => this.props.onRemoveFunction(f)}>
                  <CloseSm />
                </div>
              )}
            </div>
          </Draggable>
        </div>
      </Droppable>
    )
  }

  private onDragStart = (t: ITransformation) => this.setState({ drag: t })

  private onDrag: DraggableEventHandler = (_e, { x, y }) =>
    this.setState({ position: { x, y } })
  private onDragStop = () => {
    setTimeout(() => this.setState({ drag: null, position: { x: 0, y: 0 } }), 10)
  }

  private onDrop = (t: ITransformation) => {
    const dragged = this.state.drag
    if (dragged === null) {
      return
    }
    if (t !== dragged) {
      this.props.onReplace(t, dragged)
    }

    this.setState({ drag: null })
  }
}
