import { SIDEBAR } from '../messages'

const reduceMenuEntry =
  (dbsMap: SMap<IDirectoryEntry>, favoritesView = false) =>
  (entries: IDirectoryEntry[], entry: IMenuEntry) => {
    const children =
      entry.children?.reduce(reduceMenuEntry(dbsMap, favoritesView), []) || []
    const shouldBeIncluded = favoritesView ? children.length > 0 : true
    const lastAddedElement = entries[entries.length - 1]

    if (
      favoritesView &&
      entries.length > 1 &&
      lastAddedElement.type === 'category' &&
      entry.type === 'category'
    ) {
      entries.pop()
    }

    switch (entry.type) {
      case 'db':
        const db = dbsMap[entry.name]
        if (db) {
          delete dbsMap[entry.name]
          entries.push(db)
        }
        return entries
      case 'folder':
        if (shouldBeIncluded) {
          entries.push({
            children,
            id: entry.name,
            description: entry.name,
            expanded: entry.expanded,
            collapsable: true,
            type: 'folder',
          })
        }
        return entries
      case 'separator':
        if (!favoritesView) {
          entries.push({
            type: 'separator',
          })
        }
        return entries
      case 'category':
        entries.push({
          type: 'category',
          id: entry.name,
          description: entry.name,
        })
        return entries
      case 'archive':
        if (shouldBeIncluded) {
          entries.push({
            type: 'folder',
            id: entry.name,
            description: entry.name,
            expanded: false,
            collapsable: true,
            children,
          })
        }
        return entries
      default:
        return entries
    }
  }

export const convertMenuLayout = (
  dbs: IDirectoryEntry[],
  menu: IMenuLayout,
  favoritesView = false
): IDirectoryEntry[] => {
  const dbsMap = dbs.reduce((map: SMap<IDirectoryEntry>, db) => {
    map[db.id] = db
    return map
  }, {})

  const layout = menu.layout.reduce(reduceMenuEntry(dbsMap, favoritesView), [])

  const remainingDbs = Object.values(dbsMap)
  if (remainingDbs.length > 0) {
    layout.push(
      {
        type: 'category',
        description: SIDEBAR.DATA_DIRECTORY_TAB.OTHER_DATABASES_SHORT,
      },
      {
        type: 'folder',
        description: SIDEBAR.DATA_DIRECTORY_TAB.OTHER_DATABASES,
        expanded: false,
        collapsable: true,
        children: remainingDbs,
      }
    )
  }

  return layout
}
