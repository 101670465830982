import * as React from 'react'
import { bindBem } from '../bem'
import { ReactComponent as Rect } from 'static/rect.svg'
import { COLORS } from '../services/series'

import './Tooltip.scss'

interface ISection {
  title?: string
  name: string
  value: string
  color: number
}

interface ITProps {
  sections: ISection[]
  x: number
  y: number
  toLeft: boolean
}

export const Tooltip = ({ sections, x, y, toLeft }: ITProps) => {
  const { block, element } = bindBem('Tooltip')
  return (
    <div className={block({ toLeft })} style={{ left: x, top: y }}>
      {sections.map((section, i) => (
        <div key={i}>
          {section.title && <div className={element('Title')}>{section.title}</div>}
          <div className={element('Entry')} key={`${section.value}-${i}`}>
            <Rect color={COLORS[section.color]} className={element('Rect')} />
            <span className={element('Name')}>{section.name}</span>
            <span className={element('Value')}>{section.value ?? 'N/A'}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
