import * as React from 'react'
import { Base64 } from 'js-base64'
import { bindBem } from '../../bem'

import close from '../../static/close-dialog.svg'

import './ApiDialog.scss'

interface IProps {
  onClose: () => void
  apiLink: string
  payload: string
}

export const ApiDialog = (props: IProps) => {
  const { block, element } = bindBem('ApiDialog')
  const base64Payload = Base64.encodeURL(props.payload)
  return (
    <div className={block()}>
      <div className={element('Header')}>
        <div className={element('Title')}>API Guide</div>
        <img className={element('Close')} src={close} onClick={props.onClose} />
      </div>
      <div className={element('Text')}>
        <div className={element('ContentTitle')}>Exporting API data</div>
        <div className={element('ContentSection')}>Summary</div>
        <div className={element('Paragraph')}>
          Currently visible dataset can be downloaded in one of two formats (JSON or CSV)
          programmatically using a simple API link.
        </div>
        <div className={element('ContentSection')}>How to use this API?</div>
        <div className={element('Paragraph')}>
          <ol>
            <li>Under &quot;EXPORT API DATA&quot;, please select desired data format,</li>
            <li>Click &quot;Copy&quot; on URL,</li>
            <li>Paste the URL into the browser.</li>
          </ol>
        </div>
        <div className={element('ContentSection')}>Data format</div>
        <div className={element('Paragraph')}>
          The dataset is described by the following JSON structure:
        </div>
        <div className={element('Code')}>{props.payload}</div>
        <div className={element('Paragraph')}>
          Then it is encoded into a Base64 string:
        </div>
        <div className={element('Code')}>{base64Payload}</div>
        <div className={element('Paragraph')}>
          A valid URL specifies response format and an authentication token along with the
          payload itself:
        </div>
        <div className={element('Code')}>
          {props.apiLink}&amp;payload={base64Payload}
        </div>
        <div className={element('ContentSection')}>Important notes</div>
        <div className={element('Paragraph')}>
          <ol>
            <li>
              Data links are meant to be kept private. They contain secret authentication
              token, so all requests to the API will be made on behalf of the user who
              copied the link.
            </li>
            <li>
              API calls are restricted to 2 requests per second. Making requests more
              frequently will result in an error.
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
