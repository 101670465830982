import { CLIENT } from 'api/config'
import { MENU_LAYOUT_URL } from './endpoints'
import { IMenuLayout } from 'global'

export const fetchMenuLayout = async (): Promise<IMenuLayout> => {
  const response = await CLIENT.get(MENU_LAYOUT_URL)
  const data = response.data

  return {
    name: data.name,
    layout: data.data,
  }
}
