import { SMap } from 'global'

export const ENV = process.env.APP_ENV || 'local'
export const IS_PRODUCTION = ENV === 'prod'
export const IS_LOCAL = ENV === 'local'

export const VERSION = process.env.APP_CURRENT_GIT_SHA

export const ADMIN_URL = process.env.APP_ADMIN_URL
export const API_KEY_LANDING_URL = process.env.APP_API_KEY_LANDING_URL

export const GA_ID = !IS_LOCAL
  ? IS_PRODUCTION
    ? 'UA-127303525-1'
    : 'UA-127291264-1'
  : null

export const AMPLITUDE_KEY = IS_PRODUCTION ? 'efcc44b20034b1dafd27d21724fd7a2d' : null

export const SENTRY_DSN = !IS_LOCAL
  ? 'https://5262a2c4d5b24ac49d1eba5d066dc443@o329107.ingest.sentry.io/4503982061322240'
  : null

export const SENTRY_TRACES_SAMPLE_RATE = IS_PRODUCTION ? 0.2 : 1.0
export const SENTRY_REPLAYS_SAMPLE_RATE = 0.0

export const API_URLS: SMap<string> = {
  local: 'http://localhost:4000/',
  dev: 'https://dev.haverview.com/',
  prod: 'https://impala.haverview.com/',
  ngrok: process.env.APP_NGROK_BACKEND_URL,
}

export const API_URL = API_URLS[ENV]
