// eslint-disable max-classes-per-file

import * as React from 'react'
import { connect } from 'react-redux'
import enhanceWithClickOutside from 'react-click-outside'
import { IRootState } from '../store'
import { logout, requestPasswordResetWithToast } from '../store/User'
import { ACTIONS } from '../store/Series'

import { SettingsDropdown } from './SettingsDropdown'
import { bindBem } from '../bem'

import { ReactComponent as Avatar } from '../static/user-avatar.svg'
import { ReactComponent as Arrow } from 'static/arrow.svg'

import './AvatarMenu.scss'

export interface IState {
  expanded: boolean
  isUserProfileOpen: boolean
}

export interface IStateProps {
  user: IUser
}

export interface IActionProps {
  onLogout: () => void
  openUserProfileModal: () => void
  onChangePassword: () => void
}

export interface IProps {
  small?: boolean
}

export class BaseAvatarMenu extends React.Component<
  IProps & IStateProps & IActionProps,
  IState
> {
  isFooter = false
  state: IState = {
    expanded: false,
    isUserProfileOpen: false,
  }

  toggleExpand = () => this.setState(() => ({ expanded: !this.state.expanded }))

  render() {
    const { expanded } = this.state
    const { user, onLogout, small } = this.props
    const { block, element } = bindBem('AvatarMenu')

    return (
      <>
        <div
          className={block({ expanded, isFooter: this.isFooter, small })}
          onClick={this.toggleExpand}
          data-cy="avatar_menu"
        >
          <Avatar className={element('Photo')} title={'Avatar'} />
          {!small && <span className={element('Name')}>{user.username}</span>}
          {!small && <Arrow className={element('Chevron')} />}
          {expanded && (
            <SettingsDropdown
              isFooter={this.isFooter}
              role={user.role}
              onLogout={onLogout}
              openUserProfileModal={this.props.openUserProfileModal}
              onChangePassword={this.props.onChangePassword}
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  return { user: state.user.user }
}

const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    onLogout: () => dispatch(logout()),
    openUserProfileModal: () => dispatch(ACTIONS.setActiveModal('userProfile')),
    onChangePassword: () => dispatch(requestPasswordResetWithToast()),
  }
}

export class BaseFooterAvatarMenu extends BaseAvatarMenu {
  isFooter = true
}

export const AvatarMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(enhanceWithClickOutside(BaseAvatarMenu))

export const FooterAvatarMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(enhanceWithClickOutside(BaseFooterAvatarMenu))
