import React from 'react'

import { block } from '../bem'
import { Button } from './Button'
import { ReactComponent as Info } from 'static/info.svg'

import './ZendeskButton.scss'

export function ZendeskButton() {
  function onClick() {
    window.zE?.(() => window.zE.activate())
  }
  return (
    <Button
      onClick={onClick}
      style={'dark'}
      icon={<Info />}
      size="small"
      className={block('ZendeskButton')}
    />
  )
}

export default ZendeskButton
